import { IpAddress } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/commands/ipAddress';
import { AnonymizedUserWorkstations } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/commands/userWorkstations';
import { useMaintenancePanelContext } from 'frontend-container/components/panels/maintenancePanel/store/context';
import { KeysForOverriddenLogLevel } from 'frontend-container/utils/logger/configurationLogKeys';
import { getLoggerInstance } from 'frontend-container/utils/logger/logger';

type SendUserDeviceLogCommandParams = Pick<
  DebugInfoParams,
  'measuredInternetSpeedInMbps'
>;

type SendUserDeviceLogCommand = (
  params: SendUserDeviceLogCommandParams
) => void;

export const useSendUserDeviceLogCommand = (): SendUserDeviceLogCommand => {
  const {
    state: { environment },
  } = useMaintenancePanelContext();

  return (params) =>
    logDebugInfo({
      ...params,
      environmentDetails: {
        ipAddress: environment?.ipAddress,
        userWorkstations: environment?.userWorkstations,
      },
    });
};

interface DebugInfoParams {
  measuredInternetSpeedInMbps: number;
  environmentDetails: {
    ipAddress: IpAddress | undefined;
    userWorkstations: AnonymizedUserWorkstations | undefined;
  };
}

const logDebugInfo = (params: DebugInfoParams): void => {
  getLoggerInstance()?.debug(
    { ...params },
    {
      keyForOverriddenLogLevel: KeysForOverriddenLogLevel.UserDeviceDebugInfo,
    }
  );
};
