import { acConfig } from '@ac/library-utils/dist/declarations';
import { RegExpUtils } from '@ac/library-utils/dist/utils';

const propertyRatesRegExp = RegExpUtils.interpolate`^${acConfig.newFrontendUrls.propertyRates}.*$`;

export const isPropertyRatesModule = (
  pathname: string = window.location.pathname
): boolean => {
  return propertyRatesRegExp.test(pathname);
};

export default {
  isPropertyRatesModule,
};
