import { FC } from 'react';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';

import {
  ButtonPattern,
  ButtonSize,
  ButtonTheme,
  Color,
  ColorsPaletteKey,
  CounterColor,
  CounterContainerPosition,
  IconName,
  MenuButtonSize,
} from '@ac/web-components';

import './ButtonTrigger.scss';

interface Props {
  buttonIcon: IconName;
  statusInactiveIcon: IconName;
  inactiveColor: Color.alert | Color.gray;
  active: boolean;
  additionalClass?: string;
  triggerId?: string;
  dataTestSelector?: string;
}

export const ButtonTrigger: FC<Props> = ({
  buttonIcon,
  statusInactiveIcon,
  inactiveColor,
  additionalClass,
  active = false,
  triggerId,
  dataTestSelector,
}) => {
  const icon = active ? IconName.check : statusInactiveIcon;
  const wrapperClass = active
    ? 'status-wrapper-active'
    : inactiveColor === Color.alert
      ? 'status-wrapper-alert'
      : 'status-wrapper-gray';

  const colorStatus = active
    ? CounterColor.success
    : inactiveColor === Color.alert
      ? CounterColor.alert
      : ColorsPaletteKey.fog200;

  const isMenuV2Enabled = getIsMenuV2Enabled();

  return isMenuV2Enabled ? (
    <ac-counter-container
      position={CounterContainerPosition.spaced}
      counter={{
        color: colorStatus,
        icon,
      }}
    >
      <ac-menu-button
        size={MenuButtonSize.trigger}
        id={triggerId}
        transparent
        class="menu-infotip-status-wrapper"
        dynamicClass={additionalClass || ''}
      >
        <ac-icon icon={buttonIcon} color={Color.white} />
      </ac-menu-button>
    </ac-counter-container>
  ) : (
    <ac-button
      id={triggerId}
      pattern={ButtonPattern.tertiary}
      theme={ButtonTheme.light}
      data-test-selector={dataTestSelector}
      class="menu-infotip-status-wrapper"
      dynamicClass={additionalClass || ''}
      size={ButtonSize.lg}
    >
      <ac-flex class="status-icon-wrapper" dynamicClass={wrapperClass}>
        <ac-icon class="status-icon" icon={icon} color={Color.white} />
      </ac-flex>
      <ac-icon icon={buttonIcon} color={Color.white} />
    </ac-button>
  );
};
