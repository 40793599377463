import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import { getPropertyContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import {
  MAC_SHORTCUT_PREFIX,
  MAC_SHORTCUT_PREFIX_OLD,
  WINDOWS_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX_OLD,
} from 'frontend-container/shared/constants';

import {
  FeatureToggleName,
  HousekeepingCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const housekeepingPermissions = propertyPermissionKeys.housekeeping;

export const HOUSEKEEPING_DASHBOARD_LINK = `${acConfig.newFrontendUrls.housekeeping}/dashboard`;
export const HOUSEKEEPING_MENU_ID = 'menu-housekeeping';
export const HOUSEKEEPING_MENU_TITLE = 'MENU.HOUSEKEEPING.TITLE';
export const HOUSEKEEPING_ICON = IconName.housekeeping;

export const getHousekeepingMenu = (): MenuElement => {
  const propertyContext = getPropertyContextData();

  const attendantAssignmentFeatureToggle = propertyContext?.featureToggles.find(
    ({ featureName }) => featureName === FeatureToggleName.HousekeepingShifts
  );

  const attendantAssignmentLink = `${acConfig.newFrontendUrls.housekeeping}${
    attendantAssignmentFeatureToggle?.enabled
      ? '/attendant-assignments-v2'
      : '/attendant-assignments'
  }`;

  return {
    workspaceType: () => Workspace.PROPERTY,
    translation: HOUSEKEEPING_MENU_TITLE,
    icon: HOUSEKEEPING_ICON,
    id: HOUSEKEEPING_MENU_ID,
    settings: [
      {
        key: HousekeepingCustomerSettingsKeys.UseHousekeepingFunctionality,
        source: AccessSource.Property,
      },
    ],
    items: [
      {
        link: HOUSEKEEPING_DASHBOARD_LINK,
        aliases: [acConfig.newFrontendUrls.housekeeping],
        code: 'HousekeepingDashboard',
        id: `${HOUSEKEEPING_MENU_ID}-dashboard`,
        translation: 'MENU.HOUSEKEEPING.ITEMS.DASHBOARD',
        permissionsConfiguration: {
          permissions: [
            {
              key: housekeepingPermissions.rooms.view,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}H`,
        keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}K`,
        keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}H`,
        keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}K`,
        keyCode: 75,
      },
      {
        link: attendantAssignmentLink,
        translation: 'MENU.HOUSEKEEPING.ITEMS.ATTENDANT_ASSIGNMENT',
        id: `${HOUSEKEEPING_MENU_ID}-attendant-assignment`,
        permissionsConfiguration: {
          permissions: [
            {
              key: housekeepingPermissions.attendantAssignment.view,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}K`,
        keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}D`,
        keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}K`,
        keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}D`,
        keyCode: 68,
      },
      {
        link: `${acConfig.newFrontendUrls.housekeeping}/maintenance-dashboard`,
        translation: 'MENU.HOUSEKEEPING.ITEMS.DASHBOARD_MAINTENANCE',
        id: `${HOUSEKEEPING_MENU_ID}-dashboard-maintenance`,
        permissionsConfiguration: {
          permissions: [
            {
              key: housekeepingPermissions.maintenance.view,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}\\`,
        keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}O`,
        keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}\\`,
        keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}O`,
        keyCode: 79,
      },
      {
        link: `${acConfig.newFrontendUrls.housekeeping}/room-history`,
        translation: 'MENU.HOUSEKEEPING.ITEMS.ROOM_HISTORY',
        id: `${HOUSEKEEPING_MENU_ID}-room-history`,
        permissionsConfiguration: {
          permissions: [
            {
              key: housekeepingPermissions.roomsHistory.view,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}/`,
        keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}Y`,
        keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}/`,
        keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}Y`,
        keyCode: 89,
      },
      {
        link: `${acConfig.newFrontendUrls.housekeeping}/printed-sheets-history`,
        translation: 'MENU.HOUSEKEEPING.ITEMS.PRINTED_SHEETS_HISTORY',
        id: `${HOUSEKEEPING_MENU_ID}-printed-sheets-history`,
        permissionsConfiguration: {
          permissions: [
            {
              key: housekeepingPermissions.attendantAssignment.view,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
      },
    ],
  };
};
