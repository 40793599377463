import { useTranslation } from 'react-i18next';

import { useSharedTooltipPresenter } from '@ac/react-infrastructure';
import { IconName } from '@ac/web-components';

import './FavoriteIcon.scss';

interface Props {
  isFavorite: boolean;
  id: string;
  onClickCallback?: (isFavorite: boolean) => void;
}

export const FavoriteIcon = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const { show } = useSharedTooltipPresenter({
    attachTo: `#${props.id}-icon-wrapper`,
    for: `#${props.id}`,
  });

  return (
    <div id={`${props.id}-icon-wrapper`}>
      <ac-icon
        id={props.id}
        dynamicClass={`favorite-icon favorite-icon-star-${props.isFavorite ? 'full' : 'outline'}`}
        icon={props.isFavorite ? IconName.star : IconName.starOutline}
        onMouseEnter={(): void => {
          show({
            text: t(
              props.isFavorite
                ? 'MENU.FAVORITES.REMOVE_FROM_FAVORITES'
                : 'MENU.FAVORITES.ADD_TO_FAVORITES'
            ),
          });
        }}
        onClick={(event): void => {
          show({
            text: '',
          });
          event.stopPropagation();
          props.onClickCallback?.(!props.isFavorite);
        }}
      />
    </div>
  );
};
