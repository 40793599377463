import { AllowedContextIds } from 'frontend-container/components/Menu/components/ConfigurationContext/types';
import {
  Context,
  ContextType,
} from 'frontend-container/components/Menu/components/Context/context';
import { configurationMenu } from 'frontend-container/components/Menu/configuration/multiContextModules/configuration';
import { WorkspaceItem } from 'frontend-container/components/Menu/hooks/useWorkspaceMenuItems';
import { t } from 'frontend-container/i18n';

import { isDefined } from '@ac/library-utils/dist/utils';
import {
  ColorsPalette,
  CustomOption,
  CustomOptionAllowedComponents,
  GroupOptions,
} from '@ac/web-components';

export type TabOption = {
  name: string;
  value: string;
  optionsControlGroup?: GroupOptions;
  customOptions?: CustomOption[];
};

export const mapContextsToOptions = (contextList: Context[]): TabOption[] =>
  contextList.map((context) => {
    const regionCode = context?.regionCode ?? '';

    const customOptionsArray: CustomOption[] = [
      {
        component: CustomOptionAllowedComponents.text,
        text: [context.code, context.name].filter(isDefined).join(' - '),
      },
    ];

    if (regionCode) {
      customOptionsArray.push({
        component: CustomOptionAllowedComponents.badge,
        text: `${context?.regionCode?.toUpperCase() ?? ''}`,
        colorBadge: ColorsPalette.fog100.hex,
      });
    }

    return {
      name: [context.code, context.name].filter(isDefined).join(' - '),
      value: context.id,
      customOptions: customOptionsArray,
    };
  });

export const mapMenuItemsToOptions = (
  menuItems: WorkspaceItem | undefined,
  selected: Context | undefined
): TabOption[] => {
  const configurationMenuLink = configurationMenu.items[2].link;

  return (
    menuItems?.subMenuElements?.map((item) => {
      const code = selected?.code ?? '';
      const customOptionsArray: CustomOption[] = [
        {
          component: CustomOptionAllowedComponents.text,
          text: t(item.translation),
        },
      ];
      if (
        code &&
        item.link === configurationMenuLink &&
        selected?.type !== ContextType.PROFILE_CENTER
      ) {
        customOptionsArray.push({
          component: CustomOptionAllowedComponents.badge,
          text: code,
          colorBadge: ColorsPalette.fog100.hex,
        });
      }

      return {
        name: t(item.translation),
        value: item.link,
        optionsControlGroup: {
          groupName: 'menu',
          hideTitle: true,
          hideSeparator: true,
        },
        customOptions: customOptionsArray,
      };
    }) ?? []
  );
};

export const mapTabsToOptions = (
  buttonSelectOptions: WorkspaceItem[] | undefined
): TabOption[] => {
  const allowedContextIds: string[] = Object.values(AllowedContextIds);

  return (
    buttonSelectOptions
      ?.filter((option: WorkspaceItem) => allowedContextIds.includes(option.id))
      ?.map((option: WorkspaceItem) => ({
        name: t(option.translation),
        value: option.url,
        optionsControlGroup: {
          groupName: 'tabs',
          hideTitle: true,
          hideSeparator: false,
        },
      })) ?? []
  );
};
