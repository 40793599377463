import { isGuestOrderingConfiguration } from 'frontend-container/components/Menu/utils/modules/guestOrderingConfiguration';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const guestOrderingConfigurationAppName = 'guest-ordering-configuration';

export const registerGuestOrderingConfiguration = (): void => {
  registerApplication(
    guestOrderingConfigurationAppName,
    () =>
      loadReactApp(
        acConfig.frontendUrls.guestOrderingConfiguration,
        guestOrderingConfigurationAppName
      ),
    (location: Location) => isGuestOrderingConfiguration(location.pathname)
  );
};
