import { LegacyRef, MouseEventHandler } from 'react';
import { ExternalMenuLink } from 'frontend-container/components/Menu/components/Item/SubItem/components/ExternalMenuLink';
import { InternalMenuLink } from 'frontend-container/components/Menu/components/Item/SubItem/components/InternalMenuLink';
import { MenuElementItem } from 'frontend-container/components/Menu/types';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';

import { FlexGap } from '@ac/web-components';

import './SubItem.scss';

interface Props {
  item: MenuElementItem;
  active: boolean;
  onClick: (id: string) => void;
  onMouseOver?: MouseEventHandler<HTMLElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
  showActionArrow?: boolean;
  spacing?: FlexGap;
  itemRef?: LegacyRef<HTMLDivElement>;
  itemProps?: Record<string, unknown>;
  badgeContent?: string;
  onFavoriteIconClickHandler?: (link: string) => void;
  isShortcutWithThen?: boolean;
}

export const SubItem = ({
  item,
  active,
  onClick,
  onMouseOver,
  showActionArrow,
  onMouseLeave,
  spacing,
  itemRef,
  itemProps,
  badgeContent,
  onFavoriteIconClickHandler,
  isShortcutWithThen,
}: Props): JSX.Element => {
  const isMenuV2Enabled = getIsMenuV2Enabled();

  const wrapperClassNames = [
    'menu-subitem',
    active ? 'subitem-selected' : undefined,
    isMenuV2Enabled ? 'menu-subitem-v2' : undefined,
  ]
    .filter(Boolean)
    .join(' ');

  return item.isExternal ? (
    <ExternalMenuLink
      wrapperClasses={wrapperClassNames}
      item={item}
      itemRef={itemRef}
      itemProps={itemProps}
    />
  ) : (
    <InternalMenuLink
      wrapperClasses={wrapperClassNames}
      item={item}
      active={active}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      showActionArrow={showActionArrow}
      id={item.id}
      spacing={spacing}
      itemRef={itemRef}
      itemProps={itemProps}
      badgeContent={badgeContent}
      favoriteIcon={!!onFavoriteIconClickHandler}
      onFavoriteIconClickHandler={(link) => onFavoriteIconClickHandler?.(link)}
      isShortcutWithThen={isShortcutWithThen}
    />
  );
};
