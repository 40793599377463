import { Logger, LoggerService } from '@ac/library-api';

let logger: LoggerService | undefined;

export const getLoggerInstance = (): LoggerService | undefined => logger;

// Logger uses BaseApi so BaseApi should be initialised before Logger
// and user should be logged in (to get configuration for tenant/property, e.g. maxBatchSize)
export const initLogger = async (): Promise<LoggerService> => {
  Logger.setAsMain();
  logger = await Logger.init('container');

  return logger;
};

export const initLoggerOnce = async (): Promise<LoggerService> => {
  if (logger) {
    return logger;
  }

  return await initLogger();
};

/**
 *
 * @deprecated Use utils/errors/logError.ts
 */
export const logError = (log: string): void => {
  logger?.error(log);
  // eslint-disable-next-line no-console
  console.error(log);
};
