/* eslint-disable no-console */

import {
  BusinessContextProviderLogger,
  ExistingContextFetchingLogParams,
  NewContextFetchingLogParams,
} from 'frontend-container/shared/businessContext/provider/logger/logger';
import { generateFetchReportTable } from 'frontend-container/shared/businessContext/provider/logger/reportCreator';

const STORAGE_SETTING_KEY = 'aboveCloud.businessContextProviderLogger';

export class BusinessContextProviderConsoleLogger
  implements BusinessContextProviderLogger
{
  private isLoggingActivated: boolean = false;

  constructor() {
    this.isLoggingActivated =
      BusinessContextProviderConsoleLogger.isFeatureEnabled();
  }

  static isFeatureEnabled(): boolean {
    return localStorage.getItem(STORAGE_SETTING_KEY) === 'true';
  }

  static setFeatureEnabled(shouldCacheBeEnabled: boolean): void {
    localStorage.setItem(
      STORAGE_SETTING_KEY,
      JSON.stringify(shouldCacheBeEnabled)
    );
  }

  logNewContextFetchRequirement(params: NewContextFetchingLogParams): void {
    this.logInCollapsedGroup('Context data fetching is required', () => {
      const {
        requestedUnit,
        cacheReadResponse,
        dataFetchedFromApi,
        cacheWriteRequest,
      } = params;

      console.table(generateFetchReportTable(params));

      console.group('Detailed values');
      console.log('Requested Unit', requestedUnit);
      console.log('Cache read response', cacheReadResponse);
      console.log('Data fetched from API', dataFetchedFromApi);
      console.log('Cache update request', cacheWriteRequest);
      console.groupEnd();
    });
  }

  logKnownContext(params: ExistingContextFetchingLogParams): void {
    const { requestedUnit, cacheReadResponse } = params;

    this.logInCollapsedGroup(
      `Whole context is already in the ${
        cacheReadResponse ? 'cache' : 'memory'
      }`,
      () => {
        console.table(requestedUnit);

        if (cacheReadResponse) {
          console.log('Cache read response', cacheReadResponse);
        } else {
          console.log(
            'All data in the memory, cache database has not been opened'
          );
        }
      }
    );
  }

  logCacheClear(): void {
    this.logInCollapsedGroup('Cache cleared', () => console.trace());
  }

  private logInCollapsedGroup(groupTopic: string, content: () => void): void {
    if (!this.isLoggingActivated) {
      return;
    }

    console.groupCollapsed(`Daylight PMS Business Context - ${groupTopic}`);
    content();
    console.groupEnd();
  }
}
