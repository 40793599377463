export const ROUTER_OUTLET_SELECTOR = '#root';
export const CONTAINER_ROOT_SELECTOR = '#container-module';

export const HOSTED_ENVANY_DOMAIN = 'envany.localhost.abovecloud.io';

export const MODULE_CLASS = 'container-module';

export const MAC_SHORTCUT_PREFIX = 'G+';
export const WINDOWS_SHORTCUT_PREFIX = 'G+';

export const MAC_SHORTCUT_PREFIX_OLD = '⌥+⌘+';
export const WINDOWS_SHORTCUT_PREFIX_OLD = 'Alt+Ctrl+';
