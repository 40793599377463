import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import {
  propertyPermissionKeys,
  SalesActivitiesSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const salesActivitiesPermissions = propertyPermissionKeys.salesActivities;

const SALES_ACTIVITIES_MENU_ID = 'menu-sales-activities';

export const salesActivitiesMenu: MenuElement = {
  workspaceType: () => Workspace.PROPERTY,
  translation: 'MENU.SALES_ACTIVITIES.TITLE',
  icon: IconName.salesActivities,
  id: SALES_ACTIVITIES_MENU_ID,
  settings: [
    {
      key: SalesActivitiesSettingsKeys.SalesActivitiesFunctionality,
      source: AccessSource.Property,
    },
  ],
  permissionsConfiguration: {
    permissions: [
      {
        key: salesActivitiesPermissions.viewSalesActivities,
        source: AccessSource.Property,
      },
    ],
    permissionsConjunction: PermissionsConjunction.And,
  },
  items: [
    {
      link: `${acConfig.newFrontendUrls.taskManagement}/sales-activities/dashboard`,
      aliases: [`${acConfig.newFrontendUrls.taskManagement}/sales-activities`],
      id: `${SALES_ACTIVITIES_MENU_ID}-dashboard`,
      code: 'SalesActivitiesDashboard',
      translation: 'MENU.SALES_ACTIVITIES.ITEMS.DASHBOARD',
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}S`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}S`,
    },
  ],
};
