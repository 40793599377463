import { Branding } from 'frontend-container/shared/branding/loadBranding';

const favicon = document.querySelector('#favicon');
const faviconLg = document.querySelector('#favicon-lg');

export const updateFavicon = (branding?: Branding): void => {
  if (branding?.favicon) {
    const faviconUrl = URL.createObjectURL(branding?.favicon);
    favicon?.setAttribute('href', faviconUrl);
    faviconLg?.setAttribute('href', faviconUrl);

    return;
  }
};
