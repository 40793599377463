import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import { getPropertyContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import {
  MAC_SHORTCUT_PREFIX,
  MAC_SHORTCUT_PREFIX_OLD,
  WINDOWS_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX_OLD,
} from 'frontend-container/shared/constants';

import { propertyPermissionKeys, Setting } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const groupsPermissions = propertyPermissionKeys.groups;
const inventoryPermissions = propertyPermissionKeys.inventory;

export const GROUPS_DASHBOARD_LINK = `${acConfig.newFrontendUrls.reservationsGroups}/dashboard`;
export const GROUPS_MENU_ID = 'menu-groups';

const INTEGRATED_BOOKING_GROUP_LINK = `${acConfig.newFrontendUrls.itinerary}/integrated-booking/group/:id`;
const RESERVATIONS_GROUPS_RESERVATIONS_MODULE_LINK = `${acConfig.newFrontendUrls.reservations}/reservations-groups/:id`;

const INVENTORY_BLOCK_LINK = `${acConfig.newFrontendUrls.inventory}/block`;
const INVENTORY_BLOCKS_LINK = `${acConfig.newFrontendUrls.inventory}/blocks`;

const RESERVATIONS_GROUPS_FRONTDESK_DASHBOARD_TITLE =
  'MENU.FRONTDESK.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD';
const BLOCK_MANAGER_TITLE = 'MENU.GROUPS.BREADCRUMB.4SEGMENT.BLOCK_MANAGER';
const GROUP_DASHBOARD_TITLE =
  'MENU.GROUPS.BREADCRUMB.4SEGMENT.GROUPS_DASHBOARD';

export const getReservationsGroupsMenu = (): MenuElement => {
  const propertyContextData = getPropertyContextData();

  const hasBlocksSetting = (
    propertyContextData?.settings.effectiveSettingsDetails.find(
      ({ code }) => code === Setting.Blocks
    )?.value as {
      value: boolean;
    }
  )?.value;

  const hasViewBlockDashboardPermission =
    propertyContextData?.permissions.permissionIds.some(
      (permissionId) =>
        permissionId === inventoryPermissions.blocks.viewBlockDashboard
    );

  const groupsMenuTranslation =
    hasViewBlockDashboardPermission && hasBlocksSetting
      ? 'MENU.GROUPS.TITLE.GROUPS_AND_BLOCKS'
      : 'MENU.GROUPS.TITLE.GROUPS';

  return {
    workspaceType: () => Workspace.PROPERTY,
    translation: groupsMenuTranslation,
    icon: IconName.group,
    id: GROUPS_MENU_ID,
    items: [
      {
        link: GROUPS_DASHBOARD_LINK,
        code: 'GroupsDashboard',
        id: `${GROUPS_MENU_ID}-dashboard`,
        aliases: [
          `${acConfig.newFrontendUrls.reservations}/reservations-groups`,
          `${acConfig.newFrontendUrls.itinerary}/integrated-booking/group`,
        ],
        translation: 'MENU.GROUPS.ITEMS.DASHBOARD',
        permissionsConfiguration: {
          permissions: [
            { key: groupsPermissions.viewGroup, source: AccessSource.Property },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}G`,
        keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}G`,
        keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}G`,
        keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}G`,
        keyCode: 71,
      },
      {
        link: `${acConfig.newFrontendUrls.reservations}/reservations-groups/new/details`,
        aliases: [
          `${acConfig.newFrontendUrls.reservations}/reservations-groups/new/room-grid`,
        ],
        id: `${GROUPS_MENU_ID}-new-room-grid`,
        translation: 'MENU.GROUPS.ITEMS.NEW',
        permissionsConfiguration: {
          permissions: [
            {
              key: groupsPermissions.createGroup,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
      },
      {
        link: `${acConfig.newFrontendUrls.reservations}/reservations-groups/quick/details`,
        aliases: [
          `${acConfig.newFrontendUrls.reservations}/reservations-groups/quick/room-grid`,
        ],
        id: `${GROUPS_MENU_ID}-quick`,
        translation: 'MENU.GROUPS.ITEMS.QUICK',
        permissionsConfiguration: {
          permissions: [
            {
              key: groupsPermissions.createQuickBookingGroup,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
      },
      {
        link: `${acConfig.newFrontendUrls.reservationsGroups}/availability-dashboard`,
        translation: 'MENU.GROUPS.ITEMS.AVAILABILITY_DASHBOARD',
        id: `${GROUPS_MENU_ID}-availability-dashboard`,
        permissionsConfiguration: {
          permissions: [
            { key: groupsPermissions.viewGroup, source: AccessSource.Property },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
      },
      {
        link: `${acConfig.newFrontendUrls.inventory}/blocks`,
        id: `${GROUPS_MENU_ID}-block-manager`,
        aliases: [
          `${acConfig.newFrontendUrls.inventory}/block`,
          `${acConfig.newFrontendUrls.inventory}/block/:id/details`,
          `${acConfig.newFrontendUrls.inventory}/block/:id/room-allocation`,
        ],
        translation: 'MENU.GROUPS.ITEMS.BLOCK_MANAGER',
        permissionsConfiguration: {
          permissions: [
            {
              key: inventoryPermissions.blocks.viewBlockDashboard,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        settings: [
          {
            key: Setting.Blocks,
            source: AccessSource.Property,
          },
        ],
        itemConfiguration: {
          hasTopDivider: true,
        },
      },
    ],
    breadcrumbs4thSegment: [
      [
        `${INTEGRATED_BOOKING_GROUP_LINK}/group-details`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: GROUP_DASHBOARD_TITLE,
        },
      ],
      [
        `${INTEGRATED_BOOKING_GROUP_LINK}/booking-details`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: GROUP_DASHBOARD_TITLE,
        },
      ],
      [
        `${INTEGRATED_BOOKING_GROUP_LINK}/meetings-and-events-details`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: GROUP_DASHBOARD_TITLE,
        },
      ],
      [
        `${INTEGRATED_BOOKING_GROUP_LINK}/events-overview`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: GROUP_DASHBOARD_TITLE,
        },
      ],
      [
        `${INTEGRATED_BOOKING_GROUP_LINK}/event-details/:id`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: GROUP_DASHBOARD_TITLE,
        },
      ],
      [
        `${INTEGRATED_BOOKING_GROUP_LINK}/me-sales-activities`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: GROUP_DASHBOARD_TITLE,
        },
      ],
      [
        `${INTEGRATED_BOOKING_GROUP_LINK}/room-grid`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: GROUP_DASHBOARD_TITLE,
        },
      ],
      [
        `${INTEGRATED_BOOKING_GROUP_LINK}/rooming-list`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: GROUP_DASHBOARD_TITLE,
        },
      ],
      [
        `${INTEGRATED_BOOKING_GROUP_LINK}/group-sales-activities`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: GROUP_DASHBOARD_TITLE,
        },
      ],
      [
        `${INTEGRATED_BOOKING_GROUP_LINK}/package`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: GROUP_DASHBOARD_TITLE,
        },
      ],
      [
        `${INTEGRATED_BOOKING_GROUP_LINK}/create-event`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: GROUP_DASHBOARD_TITLE,
        },
      ],
      [
        INVENTORY_BLOCK_LINK,
        {
          link: INVENTORY_BLOCKS_LINK,
          title: BLOCK_MANAGER_TITLE,
        },
      ],
      [
        `${INVENTORY_BLOCK_LINK}/:id/details`,
        {
          link: INVENTORY_BLOCKS_LINK,
          title: BLOCK_MANAGER_TITLE,
        },
      ],
      [
        `${INVENTORY_BLOCK_LINK}/:id/room-allocation`,
        {
          link: INVENTORY_BLOCKS_LINK,
          title: BLOCK_MANAGER_TITLE,
        },
      ],
      [
        `${RESERVATIONS_GROUPS_RESERVATIONS_MODULE_LINK}/room-grid`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: RESERVATIONS_GROUPS_FRONTDESK_DASHBOARD_TITLE,
        },
      ],
      [
        `${RESERVATIONS_GROUPS_RESERVATIONS_MODULE_LINK}/rooming-list`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: RESERVATIONS_GROUPS_FRONTDESK_DASHBOARD_TITLE,
        },
      ],
      [
        `${RESERVATIONS_GROUPS_RESERVATIONS_MODULE_LINK}/sales-activities`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: RESERVATIONS_GROUPS_FRONTDESK_DASHBOARD_TITLE,
        },
      ],
      [
        `${RESERVATIONS_GROUPS_RESERVATIONS_MODULE_LINK}/details`,
        {
          link: GROUPS_DASHBOARD_LINK,
          title: RESERVATIONS_GROUPS_FRONTDESK_DASHBOARD_TITLE,
        },
      ],
    ],
  };
};
