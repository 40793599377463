import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { isConfigurationContextVisible } from 'frontend-container/components/Menu/components/ConfigurationContext/isConfigurationContextVisible';
import { isCroContextVisible } from 'frontend-container/components/Menu/components/CroContext';
import { isProfileCentersContextVisible } from 'frontend-container/components/Menu/components/ProfileCentersContext/isProfileCentersContextVisible';
import { isPropertyContextVisible } from 'frontend-container/components/Menu/components/PropertyContext';
import { centralAvailabilityInquiryMenu } from 'frontend-container/components/Menu/configuration/croModules/centralAvailabilityInquiry';
import { reservationsLookupMenu } from 'frontend-container/components/Menu/configuration/croModules/reservationsLookup';
import { changelogMenu } from 'frontend-container/components/Menu/configuration/multiContextModules/changelog';
import { campaignManagementMenu } from 'frontend-container/components/Menu/configuration/profileCenterModules/campaignManagement';
import { profileCenterMenu } from 'frontend-container/components/Menu/configuration/profileCenterModules/profileCenters';
import { getActivityReservationsMenu } from 'frontend-container/components/Menu/configuration/propertyModules/activityReservations';
import { getGolfMenu } from 'frontend-container/components/Menu/configuration/propertyModules/golf';
import { guestOrderingConfigurationMenu } from 'frontend-container/components/Menu/configuration/propertyModules/guestOrderingConfiguration';
import { getHousekeepingMenu } from 'frontend-container/components/Menu/configuration/propertyModules/housekeeping';
import { pointOfSaleMenu } from 'frontend-container/components/Menu/configuration/propertyModules/pointOfSale';
import { propertyDetailsMenu } from 'frontend-container/components/Menu/configuration/propertyModules/propertyDetails';
import { propertyUsers } from 'frontend-container/components/Menu/configuration/propertyModules/propertyUsers';
import { rateManagerMenu } from 'frontend-container/components/Menu/configuration/propertyModules/rateManager';
import { frontDeskMenu } from 'frontend-container/components/Menu/configuration/propertyModules/reservations/frontdesk';
import { inquiryMenu } from 'frontend-container/components/Menu/configuration/propertyModules/reservations/inquiry';
import { getReservationsGroupsMenu } from 'frontend-container/components/Menu/configuration/propertyModules/reservationsGroups';
import { resourceManagementMenu } from 'frontend-container/components/Menu/configuration/propertyModules/resourceManagement';
import { salesActivitiesMenu } from 'frontend-container/components/Menu/configuration/propertyModules/taskManagement/salesActivities';
import { travelAgentCommissionsMenu } from 'frontend-container/components/Menu/configuration/propertyModules/travelAgentComissions';
import { workflowsMenu } from 'frontend-container/components/Menu/configuration/propertyModules/workflows';
import { croStructureMenu } from 'frontend-container/components/Menu/configuration/tenantModules/configuration/croStructure';
import { configurationProfileCentersMenu } from 'frontend-container/components/Menu/configuration/tenantModules/configuration/profileCenterStructure';
import { getReportingMenuItems } from 'frontend-container/components/Menu/configuration/utils/getReportingMenuItems';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';

import {
  configurationMenu,
  getConfigurationMenuInMainApplication,
} from './multiContextModules/configuration';
import { accountsReceivableMenu } from './propertyModules/accountsReceivable';
import { cashieringMenu } from './propertyModules/cashiering/cashiering';
import { endOfDayMenu } from './propertyModules/cashiering/endOfDay';
import { meetingsAndEventsMenu } from './propertyModules/meetingsAndEvents';
import { profilesMenu } from './propertyModules/profiles';
import { reportsMenu } from './propertyModules/reports';
import { reservationsMenu } from './propertyModules/reservations/reservations';
import { taskManagementMenu } from './propertyModules/taskManagement/taskManagement';
import { applicationDashboardsMenu } from './tenantModules/application';
import { organizationStructureMenu } from './tenantModules/configuration/organizationStructure';
import {
  customersMenuFull,
  customersMenuReadOnlyMode,
} from './tenantModules/configuration/systemUser/customers';
import { getSystemUsersMenu as getSystemUsersMenuElement } from './tenantModules/configuration/systemUser/systemUsers';
import { usersMenu } from './tenantModules/configuration/users';
import {
  integrationsMenu,
  integrationsMenuForSystemUser,
} from './tenantModules/integrations';

export const menuItemHeightInPx = 42;
const menuHeaderHeightInPx = 32;

/**
 * @privateRemarks
 * Remove with MenuV1
 */
const fillShiftForMenuElement = (menuElement: MenuElement): MenuElement => {
  if (!menuElement.items?.length) {
    menuElement.items = [];
  }

  return {
    ...menuElement,
    shift:
      (menuItemHeightInPx * menuElement.items.length + menuHeaderHeightInPx) /
        2 -
      menuHeaderHeightInPx / 2,
  };
};

const getIntegrationsMenu = (): MenuElement =>
  LoginService.isSuperUser() ? integrationsMenuForSystemUser : integrationsMenu;

export const getProfileCenterMenu = (): MenuElement[] => [
  profileCenterMenu,
  campaignManagementMenu,
];
export const getCentralReservationOfficeMenu = (): MenuElement[] => [
  centralAvailabilityInquiryMenu,
  reservationsLookupMenu,
];
export const getConfigurationMenu = (): MenuElement[] => {
  let menuElements: MenuElement[];
  const isMenuV2Enabled = getIsMenuV2Enabled();

  if (LoginService.isSuperUser()) {
    menuElements = [
      SessionService.getReadOnlyMode()
        ? customersMenuReadOnlyMode
        : customersMenuFull,
      getSystemUsersMenuElement(),
      changelogMenu,
      integrationsMenuForSystemUser,
    ];
  } else {
    menuElements = [
      organizationStructureMenu,
      configurationProfileCentersMenu,
      croStructureMenu,
      usersMenu,
      propertyDetailsMenu,
    ];

    if (!isMenuV2Enabled) {
      menuElements = [...menuElements, integrationsMenu];
    }
  }

  return menuElements;
};

export const getFullMenu = (): MenuElement[] => [
  // Change in order may break DocumentTitle service.
  getSystemUsersMenuElement(),
  customersMenuFull,
  getIntegrationsMenu(),
  organizationStructureMenu,
  croStructureMenu,
  ...getCentralReservationOfficeMenu(),
  ...getProfileCenterMenu(),
  usersMenu,
  ...getMenuV1MainApplicationMenu(),
  applicationDashboardsMenu,
  getConfigurationMenuInMainApplication(),
  changelogMenu,
];

export const getPropertyMenu = (): MenuElement[] => [
  accountsReceivableMenu,
  meetingsAndEventsMenu,
  taskManagementMenu,
  reservationsMenu,
  getGolfMenu(),
  getActivityReservationsMenu(),
  getReservationsGroupsMenu(),
  getHousekeepingMenu(),
  rateManagerMenu,
  workflowsMenu,
  profilesMenu,
  cashieringMenu,
  resourceManagementMenu,
  frontDeskMenu,
  endOfDayMenu,
  {
    ...reportsMenu,
    items: [...reportsMenu.items, ...getReportingMenuItems()],
  },
  travelAgentCommissionsMenu,
  getIntegrationsMenu(),
  changelogMenu,
  inquiryMenu,
  salesActivitiesMenu,
  propertyUsers,
  guestOrderingConfigurationMenu,
  pointOfSaleMenu,
];

export const getAllowedConfigurationMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = getConfigurationMenu();

  return getAllowedMenuItems(menuElements, configuration);
};

export const getAllowedCentralReservationOfficeMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = getCentralReservationOfficeMenu();

  return getAllowedMenuItems(menuElements, configuration);
};

export const getAllowedProfileCenterMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = getProfileCenterMenu();

  return getAllowedMenuItems(menuElements, configuration);
};

export const getAllowedPropertyMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = getPropertyMenu();

  return getAllowedMenuItems(menuElements, configuration);
};

export const getSystemUserMenu = (): MenuElement[] => {
  const menuElements = [
    SessionService.getReadOnlyMode()
      ? customersMenuReadOnlyMode
      : customersMenuFull,
    getSystemUsersMenuElement(),
    changelogMenu,
    integrationsMenuForSystemUser,
  ];

  return menuElements;
};

export const getAllowedSystemUserMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = getSystemUserMenu();

  return getAllowedMenuItems(menuElements, configuration);
};

export const getMainApplicationMenu = (): MenuElement[] => {
  if (!getIsMenuV2Enabled()) {
    return getMenuV1MainApplicationMenu();
  }

  return [
    getSystemUsersMenuElement(),
    ...getPropertyMenu(),
    ...getCentralReservationOfficeMenu(),
    ...getProfileCenterMenu(),
    ...getConfigurationMenu(),
  ];
};

export const getAllowedMainApplicationMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  if (LoginService.isSuperUser()) {
    return getAllowedSystemUserMenu(configuration);
  }

  return getAllowedMenuItems(getMainApplicationMenu(), configuration);
};

export const getCurrentWorkspaceMenuElements = (): MenuElement[] => {
  if (LoginService.isSuperUser()) {
    return getSystemUserMenu();
  }

  if (isCroContextVisible()) {
    return getCentralReservationOfficeMenu();
  }

  if (isProfileCentersContextVisible()) {
    return getProfileCenterMenu();
  }

  if (isConfigurationContextVisible()) {
    return getConfigurationMenu();
  }

  if (isPropertyContextVisible()) {
    return getPropertyMenu();
  }

  return [];
};

export const getAllowedCurrentWorkspaceMenuElements = (
  allAccessConfiguration: AllAccessConfiguration
): MenuElement[] => {
  return getAllowedMenuItems(
    getCurrentWorkspaceMenuElements(),
    allAccessConfiguration
  );
};

/**
 * @deprecated
 * For Menu elements
 * - use getAllowedMainApplicationMenu
 *
 * For Separated by context menu elements
 * - getAllowedPropertyItems
 * - getAllowedCentralReservationOfficeMenu
 * - getAllowedProfileCenterItems
 * - getAllowedConfigurationItems
 *
 * For System user menu elements
 * - getAllowedSystemUserMenu
 */
export const getAllowedChangelogMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  let menuElements: MenuElement[];

  if (LoginService.isSuperUser()) {
    menuElements = [
      SessionService.getReadOnlyMode()
        ? customersMenuReadOnlyMode
        : customersMenuFull,
      getSystemUsersMenuElement(),
      changelogMenu,
      integrationsMenuForSystemUser,
    ];
  } else {
    menuElements = [changelogMenu];
  }

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};

/**
 * @deprecated
 * For Menu elements
 * - use getAllowedMainApplicationMenu
 *
 * For Separated by context menu elements
 * - getAllowedPropertyItems
 * - getAllowedCentralReservationOfficeMenu
 * - getAllowedProfileCenterItems
 * - getAllowedConfigurationItems
 *
 * For System user menu elements
 * - getAllowedSystemUserMenu
 */
export const getAllowedWorkflowsMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = [
    workflowsMenu,
    changelogMenu,
    configurationMenu,
    applicationDashboardsMenu,
  ];

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};

/**
 * @deprecated
 * For Menu elements
 * - use getAllowedMainApplicationMenu
 *
 * For Separated by context menu elements
 * - getAllowedPropertyItems
 * - getAllowedCentralReservationOfficeMenu
 * - getAllowedProfileCenterItems
 * - getAllowedConfigurationItems
 *
 * For System user menu elements
 * - getAllowedSystemUserMenu
 */
export const getMenuV1MainApplicationMenu = (): MenuElement[] =>
  [
    inquiryMenu,
    profilesMenu,
    reservationsMenu,
    getGolfMenu(),
    getActivityReservationsMenu(),
    resourceManagementMenu,
    rateManagerMenu,
    getReservationsGroupsMenu(),
    frontDeskMenu,
    cashieringMenu,
    getHousekeepingMenu(),
    taskManagementMenu,
    {
      ...reportsMenu,
      items: [...reportsMenu.items, ...getReportingMenuItems()],
    },
    endOfDayMenu,
    meetingsAndEventsMenu,
    salesActivitiesMenu,
    accountsReceivableMenu,
    travelAgentCommissionsMenu,
    getIntegrationsMenu(),
    changelogMenu,
    propertyUsers,
    guestOrderingConfigurationMenu,
    pointOfSaleMenu,
  ].map(fillShiftForMenuElement);
