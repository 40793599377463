import { useTranslation } from 'react-i18next';
import { isMac } from 'frontend-container/utils/isMac';

import { AlignItems, JustifyContent, TextColor } from '@ac/web-components';

import './Shortcut.scss';

interface Props {
  text: string;
  withoutThen?: boolean;
}

export const Shortcut = ({ text, withoutThen }: Props): JSX.Element | null => {
  const { t } = useTranslation();

  if (withoutThen) {
    return (
      <ac-flex
        class="link-shortcut ac-margin-inline-start-sm"
        alignItems={AlignItems.center}
        justifyContent={JustifyContent.center}
      >
        <ac-text color={TextColor.foreground}>{text}</ac-text>
      </ac-flex>
    );
  }

  const splitText = isMac ? text.split(' ') : text.split('+');

  return (
    <ac-flex alignItems={AlignItems.center}>
      <ac-flex
        class="link-shortcut ac-margin-inline-start-sm"
        alignItems={AlignItems.center}
        justifyContent={JustifyContent.center}
      >
        <ac-text color={TextColor.foreground}>{splitText[0]}</ac-text>
      </ac-flex>
      <ac-text class="ac-spacing-horizontal-sm" color={TextColor.foreground}>
        {t('COMMON.THEN')}
      </ac-text>
      <ac-flex
        class="link-shortcut"
        alignItems={AlignItems.center}
        justifyContent={JustifyContent.center}
      >
        <ac-text color={TextColor.foreground}>{splitText[1]}</ac-text>
      </ac-flex>
    </ac-flex>
  );
};
