import { useHotkeys } from 'react-hotkeys-hook';
import { handleUnsavedChangesRedirect } from 'frontend-container/components/Menu/components/Item/handleUnsavedChangesRedirect';
import {
  getMainApplicationMenu,
  getMenuV1MainApplicationMenu,
} from 'frontend-container/components/Menu/configuration';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';

const handleOnClickMenuSubItem = async (
  discardUnsavedChanges: boolean,
  link: string,
  callback: (link: string) => void
): Promise<void> =>
  await handleUnsavedChangesRedirect(discardUnsavedChanges, link, callback);

export const useMenuShortcutsOld = (
  discardUnsavedChangesOnRedirect: boolean,
  redirectCallback: (link: string) => void
): void => {
  const isMenuV2Enabled = getIsMenuV2Enabled();
  const menuElements = isMenuV2Enabled
    ? getMainApplicationMenu()
    : getMenuV1MainApplicationMenu();

  const shortcutsWindows = menuElements.flatMap((element: MenuElement) =>
    element.items.filter((item) => item.keyboardShortcutWindowsOld)
  );
  const shortcutsMac = menuElements.flatMap((element: MenuElement) =>
    element.items.filter((item) => item.keyboardShortcutMacOld)
  );
  const isMac = navigator.appVersion.indexOf('Mac') !== -1;
  const keyboardShortcuts = isMac
    ? shortcutsMac.map((item: MenuElementItem) => item.keyboardShortcutMacOld)
    : shortcutsWindows.map(
        (item: MenuElementItem) => item.keyboardShortcutWindowsOld
      );

  useHotkeys(
    keyboardShortcuts.join(),
    (handler: {
      altKey: boolean;
      ctrlKey: boolean;
      metaKey: boolean;
      keyCode: number;
    }) => {
      const shortcuts = isMac ? shortcutsMac : shortcutsWindows;

      const foundMenuItem = shortcuts.find((item: MenuElementItem) => {
        const { altKey, ctrlKey, metaKey, keyCode } = handler;
        const isTheSameKeyCode = keyCode === item.keyCode;

        return altKey && (isMac ? metaKey : ctrlKey) && isTheSameKeyCode;
      });

      if (foundMenuItem) {
        handleOnClickMenuSubItem(
          discardUnsavedChangesOnRedirect,
          foundMenuItem.link,
          redirectCallback
        );
      }
    }
  );
};
