import React from 'react';
import { getEmberAppLoadingStatus } from 'frontend-container/utils/externalResources/ember';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { safeLoadReactComponent } from '@ac/library-utils/dist/utils/micro-frontends/loading/component/react';
import { ComponentToRender } from '@ac/library-utils/dist/utils/micro-frontends/rendering/shared';
import { ReactPublicComponentsLoader } from '@ac/react-infrastructure';

const PANEL_COMPONENT_NAME = 'PublicPanelWrapper';

interface Props {
  isPanelVisible: boolean;
  isPropertyContext: boolean;
  onPanelClose: () => void;
  unreadNotificationsCount: number;
}

export const loadNotificationsPanel = async (): Promise<
  ComponentToRender<Props>
> => {
  await getEmberAppLoadingStatus();
  const parcelConfig = await safeLoadReactComponent(
    acConfig.frontendUrls.notifications,
    PANEL_COMPONENT_NAME
  );

  return {
    ...parcelConfig,
    name: PANEL_COMPONENT_NAME,
  };
};

export const NotificationPanel = (props: Props): JSX.Element => {
  return (
    <ReactPublicComponentsLoader
      loadComponent={loadNotificationsPanel}
      externalComponentProps={props}
      name={PANEL_COMPONENT_NAME}
      isMemo
    />
  );
};
