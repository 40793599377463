import { AccessSource } from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';

import { FeatureToggleName } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const POINT_OF_SALE_MENU_ID = 'menu-point-of-sale';

export const pointOfSaleMenu: MenuElement = {
  workspaceType: () => Workspace.PROPERTY,
  translation: 'MENU.POINT_OF_SALE.TITLE',
  icon: IconName.cashbox,
  id: POINT_OF_SALE_MENU_ID,
  featureToggles: [
    {
      key: FeatureToggleName.PointOfSale,
      source: AccessSource.Property,
    },
  ],
  items: [
    {
      link: `${acConfig.newFrontendUrls.pointOfSale}/dashboard`,
      aliases: [`${acConfig.newFrontendUrls.pointOfSale}`],
      id: `${POINT_OF_SALE_MENU_ID}-dashboard`,
      translation: 'MENU.POINT_OF_SALE.ITEMS.DASHBOARD',
    },
  ],
};
