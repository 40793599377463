import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextDropdown } from 'frontend-container/components/Menu/components/Context/components/ContextDropdown';
import { ContextHeader } from 'frontend-container/components/Menu/components/Context/components/ContextHeader';
import { useSessionContextTypeIds } from 'frontend-container/components/Menu/components/Context/useSessionContextTypeIds';
import { getPropertyContexts } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { fetchPropertyBrandingColor } from 'frontend-container/components/Menu/components/PropertySelectorReadOnly/fetchPropertyBrandingColor';
import { usePropertyReadOnlyDate } from 'frontend-container/components/Menu/components/PropertySelectorReadOnly/usePropertyReadOnlyDateTime';

import {
  DividerDirection,
  InfotipSize,
  InfotipSlot,
  TargetValueObject,
} from '@ac/web-components';

interface Props {
  isHidden?: boolean;
}

export const PropertySelectorReadOnly = ({
  isHidden,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();

  const [brandingColor, setBrandingColor] = useState<string>();
  const propertyDate = usePropertyReadOnlyDate();
  const { propertyId } = useSessionContextTypeIds();

  const context = useMemo(() => {
    const propertyContexts = getPropertyContexts();

    return propertyContexts.find(({ id }) => id === propertyId);
  }, [propertyId]);

  useEffect(() => {
    if (!context || !context.id) {
      return;
    }

    const loadPropertyBrandingColor = async (): Promise<void> => {
      const result = await fetchPropertyBrandingColor(context.id);

      if (result) {
        setBrandingColor(result);
      }
    };

    loadPropertyBrandingColor();
  }, [context]);

  if (!context || isHidden) {
    return null;
  }

  return (
    <div id="context-list-header-wrapper">
      <ac-flex>
        <ac-infotip
          for="#read-only-property-selector-button"
          attachTo="#context-list-header-wrapper"
          targetValue={TargetValueObject.mainMenu}
          size={InfotipSize.sm}
        >
          <div slot={InfotipSlot.component}>
            <ContextDropdown
              id="read-only-property-selector-button"
              selectedContext={context}
              businessDate={propertyDate?.date}
              dateFormat={propertyDate?.dateFormat}
              propertyTime={propertyDate?.time}
              color={brandingColor}
            />
          </div>
          <div slot={InfotipSlot.content}>
            <ContextHeader
              selectedContext={context}
              date={propertyDate?.date}
              time={propertyDate?.time}
              dateLabel={t('MENU.CONTEXT.PROPERTY.DATE')}
              timeLabel={t('MENU.CONTEXT.PROPERTY.TIME')}
              isInsideTooltip
            />
          </div>
        </ac-infotip>
        <ac-divider direction={DividerDirection.vertical} />
      </ac-flex>
    </div>
  );
};
