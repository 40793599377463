import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import { isFeatureToggleEnabled } from 'frontend-container/components/Menu/utils/isFeatureToggleEnabled';
import {
  MAC_SHORTCUT_PREFIX,
  MAC_SHORTCUT_PREFIX_OLD,
  WINDOWS_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX_OLD,
} from 'frontend-container/shared/constants';

import { FeatureToggleName, propertyPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const INQUIRY_MENU_ID = 'menu-inquiry';

export const RESERVATIONS_INQUIRY_DASHBOARD_LINK = `${acConfig.newFrontendUrls.reservations}/reservations/inquiry`;
export const INDIVIDUAL_RESERVATIONS_INQUIRY_DASHBOARD_LINK = `${acConfig.newFrontendUrls.individualReservations}/inquiry`;

export const inquiryMenu: MenuElement = {
  workspaceType: () => Workspace.PROPERTY,
  translation: 'MENU.INQUIRY.TITLE',
  icon: IconName.calendarPeriod,
  id: INQUIRY_MENU_ID,
  items: [
    {
      get link(): string {
        const isReactInquiryEnabled = isFeatureToggleEnabled(
          FeatureToggleName.EmberToReactInquiryDashboardReactEnabled
        );

        return isReactInquiryEnabled
          ? INDIVIDUAL_RESERVATIONS_INQUIRY_DASHBOARD_LINK
          : RESERVATIONS_INQUIRY_DASHBOARD_LINK;
      },
      code: 'InquiryDashboard',
      id: `${INQUIRY_MENU_ID}-dashboard`,
      translation: 'MENU.INQUIRY.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: propertyPermissionKeys.reservations.dashboard
              .viewGeneralInquiry,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}I`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}I`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}I`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}I`,
      keyCode: 73,
    },
  ],
};
