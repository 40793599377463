import { AccessSource } from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';

import { FeatureToggleName } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const GUEST_ORDERING_CONFIGURATION_MENU_ID =
  'menu-guest-ordering-configuration';

export const guestOrderingConfigurationMenu: MenuElement = {
  workspaceType: () => Workspace.PROPERTY,
  translation: 'MENU.GUEST_ORDERING_CONFIGURATION.TITLE',
  icon: IconName.food,
  id: GUEST_ORDERING_CONFIGURATION_MENU_ID,
  featureToggles: [
    {
      key: FeatureToggleName.GuestOrderingConfiguration,
      source: AccessSource.Property,
    },
  ],
  items: [
    {
      link: `${acConfig.newFrontendUrls.guestOrderingConfiguration}/dashboard`,
      aliases: [`${acConfig.newFrontendUrls.guestOrderingConfiguration}`],
      id: `${GUEST_ORDERING_CONFIGURATION_MENU_ID}-dashboard`,
      translation: 'MENU.GUEST_ORDERING_CONFIGURATION.ITEMS.DASHBOARD',
    },
  ],
};
