import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  MAC_SHORTCUT_PREFIX_OLD,
  WINDOWS_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX_OLD,
} from 'frontend-container/shared/constants';

import {
  GeneralCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const reportsDashboardLink = `${acConfig.newFrontendUrls.reports}/folders`;

export const REPORTS_MENU_ID = 'menu-reports';

export const reportsMenu: MenuElement = {
  workspaceType: () => Workspace.PROPERTY,
  translation: 'MENU.REPORTS.TITLE',
  icon: IconName.report,
  id: REPORTS_MENU_ID,
  items: [
    {
      link: reportsDashboardLink,
      id: `${REPORTS_MENU_ID}-dashboard`,
      aliases: [acConfig.newFrontendUrls.reports],
      translation: 'MENU.REPORTS.ITEMS.DASHBOARD',
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}O`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}R`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}O`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}R`,
      keyCode: 82,
    },
    {
      link: `${acConfig.newFrontendUrls.reports}/batch-reports`,
      id: `${REPORTS_MENU_ID}-batch-report-dashboard`,
      translation: 'MENU.REPORTS.ITEMS.BATCH_REPORT_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: propertyPermissionKeys.report.executeBatchReport,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX},`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD},`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX},`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD},`,
      keyCode: 188,
    },
    {
      link: `${acConfig.newFrontendUrls.reports}/scheduler`,
      id: `${REPORTS_MENU_ID}-scheduler`,
      translation: 'MENU.SCHEDULER.ITEMS.DASHBOARD',
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}\``,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}\``,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}\``,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}\``,
      keyCode: 192,
    },
    {
      link: `${acConfig.newFrontendUrls.reports}/run-log`,
      translation: 'MENU.REPORTS.ITEMS.REPORT_RUN_LOG_DASHBOARD',
      id: `${REPORTS_MENU_ID}-run-log-dashboard`,
      aliases: [
        `${acConfig.newFrontendUrls.reports}/run-log/report`,
        `${acConfig.newFrontendUrls.reports}/run-log/batch`,
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/exports/dashboard`,
      translation: 'MENU.INTEGRATIONS.ITEMS.EXPORTS_DASHBOARD',
      id: `${REPORTS_MENU_ID}-exports-dashboard`,
      permissionsConfiguration: {
        permissions: [
          {
            key: propertyPermissionKeys.integrations.fileExportViewHistory,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/property/entity-manager`,
      translation: 'MENU.REPORTS.ITEMS.DATA_SET_TRACKING',
      id: `${REPORTS_MENU_ID}-data-set-tracking`,
      settings: [
        {
          key: GeneralCustomerSettingsKeys.DataSetTracking,
          source: AccessSource.Property,
        },
      ],
    },
  ],
};
