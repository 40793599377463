import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import { isFeatureToggleEnabled } from 'frontend-container/components/Menu/utils/isFeatureToggleEnabled';
import { getPropertyContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import {
  MAC_SHORTCUT_PREFIX,
  MAC_SHORTCUT_PREFIX_OLD,
  WINDOWS_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX_OLD,
} from 'frontend-container/shared/constants';

import {
  FeatureToggleName,
  propertyPermissionKeys,
  Setting,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const reservationPermissions = propertyPermissionKeys.reservations;
const inventoryPermissions = propertyPermissionKeys.inventory;
export const RESERVATIONS_DASHBOARD_LINK = `${acConfig.newFrontendUrls.reservations}/reservations`;
export const INDIVIDUAL_RESERVATIONS_DASHBOARD_LINK = `${acConfig.newFrontendUrls.individualReservations}/dashboard`;
export const RESERVATIONS_AVAILABILITY_LINK = `${acConfig.newFrontendUrls.reservations}/availability/search`;
export const AVAILABILITY_INQUIRY_LINK = `${acConfig.newFrontendUrls.availability}/search`;
export const AVAILABILITY_DETAILED_LINK = `${acConfig.newFrontendUrls.reports}/availability/detailed`;
export const CREATE_RESERVATION_LINK = `${acConfig.newFrontendUrls.profiles}/prestep/reservation`;

const RESERVATIONS_MENU_ID = 'menu-reservations';

const INVENTORY_ITEM_LINK = '/inventory/items/inventory-item';
const INVENTORY_ITEMS_LINK = 'inventory/items';
const INVENTORY_ITEM_DASHBOARD_TITLE =
  'MENU.RESERVATIONS.BREADCRUMB.4SEGMENT.INVENTORY_ITEM_DASHBOARD';

const RESERVATIONS_DASHBOARD_TITLE =
  'MENU.RESERVATIONS.BREADCRUMB.4SEGMENT.RESERVATIONS_DASHBOARD';

export const reservationsMenu: MenuElement = {
  workspaceType: () => Workspace.PROPERTY,
  translation: 'MENU.RESERVATIONS.TITLE',
  icon: IconName.reservations,
  id: RESERVATIONS_MENU_ID,
  items: [
    {
      get link(): string {
        const isEmberToReactReservationsDashboardReactEnabled =
          isFeatureToggleEnabled(
            FeatureToggleName.EmberToReactReservationsDashboardReactEnabled
          );

        return isEmberToReactReservationsDashboardReactEnabled
          ? INDIVIDUAL_RESERVATIONS_DASHBOARD_LINK
          : RESERVATIONS_DASHBOARD_LINK;
      },
      aliases: [
        acConfig.newFrontendUrls.reservations,
        `${acConfig.newFrontendUrls.individualReservations}/dashboard`,
        `${acConfig.newFrontendUrls.individualReservations}/reservation/new`,
        `${acConfig.newFrontendUrls.individualReservations}/reservation`,
      ],
      id: `${RESERVATIONS_MENU_ID}-dashboard`,
      code: 'ReservationDashboard',
      translation: 'MENU.RESERVATIONS.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.dashboard.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}R`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}V`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}R`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}V`,
      keyCode: 86,
    },
    {
      get link(): string {
        const isReactAvailabilityEnabled = isFeatureToggleEnabled(
          FeatureToggleName.EmberToReactAvailabilityInquiryReactEnabled
        );

        return isReactAvailabilityEnabled
          ? AVAILABILITY_INQUIRY_LINK
          : RESERVATIONS_AVAILABILITY_LINK;
      },
      id: `${RESERVATIONS_MENU_ID}-availability`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/availability/search/room-types`,
        `${acConfig.newFrontendUrls.reservations}/availability/search/rate-plans`,
        acConfig.newFrontendUrls.availability,
      ],
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.manageReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      translation: 'MENU.RESERVATIONS.ITEMS.AVAILABILITY',
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}Q`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}Q`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}Q`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}Q`,
      keyCode: 81,
    },
    {
      link: AVAILABILITY_DETAILED_LINK,
      code: 'RoomTypeAvailability',
      id: `${RESERVATIONS_MENU_ID}-room-type-availability`,
      translation: 'MENU.RESERVATIONS.ITEMS.ROOM_TYPE_AVAILABILITY',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.viewReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}1`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}X`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}1`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}X`,
      keyCode: 88,
    },
    {
      link: `${acConfig.newFrontendUrls.rateManager}/inventory-balance-dashboard`,
      id: `${RESERVATIONS_MENU_ID}-inventory-balance-dashboard`,
      featureToggles: [
        {
          key: FeatureToggleName.IntegrationIHG,
          source: AccessSource.Property,
        },
      ],
      translation: 'MENU.RATES.ITEMS.INVENTORY_BALANCE_DASHBOARD',
    },
    {
      link: CREATE_RESERVATION_LINK,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/reservations/new`,
        `${acConfig.newFrontendUrls.itinerary}/itinerary/new`,
        `${acConfig.newFrontendUrls.itinerary}/itinerary`,
      ],
      translation: 'MENU.RESERVATIONS.ITEMS.NEW',
      id: `${RESERVATIONS_MENU_ID}-create`,
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.manageReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'N+R',
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}1`,
      keyboardShortcutMac: 'N+R',
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}1`,
      keyCode: 49,
    },
    {
      link: `${acConfig.newFrontendUrls.inventory}/items`,
      id: `${RESERVATIONS_MENU_ID}-inventory-items`,
      aliases: [acConfig.newFrontendUrls.inventory],
      code: 'ItemInventoryDashboard',
      translation: 'MENU.RESERVATIONS.ITEMS.INVENTORY_ITEMS',
      settings: [
        { key: Setting.InventoryItems, source: AccessSource.Property },
      ],
      permissionsConfiguration: {
        permissions: [
          {
            key: inventoryPermissions.viewItemInventoryDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.propertyRates}/reservation-offers`,
      id: `${RESERVATIONS_MENU_ID}-reservation-offers`,
      aliases: [acConfig.newFrontendUrls.propertyRates],
      code: 'ReservationOffers',
      translation: 'MENU.RESERVATIONS.ITEMS.RESERVATION_OFFERS',
      settings: [
        { key: Setting.ReservationOffers, source: AccessSource.Property },
      ],
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservationOffers
              .viewReservationOfferDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
  breadcrumbs4thSegment: [
    [
      '/reservations/reservations/:id',
      {
        get link(): string {
          const propertyContextData = getPropertyContextData();

          const isEmberToReactReservationsDashboardReactEnabled =
            propertyContextData?.featureToggles.find((toggle) => {
              return (
                toggle.featureName ===
                FeatureToggleName.EmberToReactReservationsDashboardReactEnabled
              );
            })?.enabled;

          return isEmberToReactReservationsDashboardReactEnabled
            ? 'individual-reservations/dashboard'
            : 'reservations/reservations';
        },
        title: RESERVATIONS_DASHBOARD_TITLE,
      },
    ],
    [
      INVENTORY_ITEM_LINK,
      { link: INVENTORY_ITEMS_LINK, title: INVENTORY_ITEM_DASHBOARD_TITLE },
    ],
    [
      `${INVENTORY_ITEM_LINK}/:id/assignment-timeline`,
      { link: INVENTORY_ITEMS_LINK, title: INVENTORY_ITEM_DASHBOARD_TITLE },
    ],
  ],
};
