import {
  AccessSource,
  AllAccessConfiguration,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  MAC_SHORTCUT_PREFIX_OLD,
  WINDOWS_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX_OLD,
} from 'frontend-container/shared/constants';

import {
  FeatureToggleName,
  ProfilesCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const profilesPermissions = propertyPermissionKeys.profiles;
const individualProfilePermissions = profilesPermissions.individual;
const profileSharedPermissions = profilesPermissions.shared;

export const PROFILES_DASHBOARD_LINK = `${acConfig.newFrontendUrls.profiles}/dashboard`;

const RESERVATIONS_PROFILES_INDIVIDUAL_LINK = `/reservations/profiles/individual/:id`;
const RESERVATIONS_PROFILES_TRAVEL_AGENT_LINK = `/reservations/profiles/travel-agent/:id`;
const RESERVATIONS_PROFILES_COMPANY_LINK = `/reservations/profiles/company/:id/`;
const PROFILES_TITLE = 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DASHBOARD';

const PROFILES_MENU_ID = 'menu-profiles';

export const profilesMenu: MenuElement = {
  workspaceType: () => Workspace.PROPERTY,
  translation: 'MENU.PROFILES.TITLE',
  icon: IconName.profile,
  id: PROFILES_MENU_ID,
  items: [
    {
      link: PROFILES_DASHBOARD_LINK,
      code: 'ProfileDashboard',
      id: `${PROFILES_MENU_ID}-dashboard`,
      aliases: [
        `${acConfig.newFrontendUrls.profiles}/dashboard/all`,
        `${acConfig.newFrontendUrls.profiles}/dashboard/individual`,
        `${acConfig.newFrontendUrls.profiles}/dashboard/company`,
        `${acConfig.newFrontendUrls.profiles}/dashboard/travel-agent`,

        // required for proper handling selected item on profile edit pages
        // simplify with ACFC-3461
        `${acConfig.newFrontendUrls.reservations}/profiles/individual`,
        `${acConfig.newFrontendUrls.reservations}/profiles/company`,
        `${acConfig.newFrontendUrls.reservations}/profiles/travel-agent`,
        `${acConfig.newFrontendUrls.reservations}/profiles/individual/:profileId/details`,
        `${acConfig.newFrontendUrls.reservations}/profiles/company/:profileId/details`,
        `${acConfig.newFrontendUrls.reservations}/profiles/travel-agent/:profileId/details`,
        `${acConfig.newFrontendUrls.reservations}/profiles/individual/:profileId/reservations`,
        `
        ${acConfig.newFrontendUrls.reservations}/profiles/individual/:profileId/reservations/current`,
        `
        ${acConfig.newFrontendUrls.reservations}/profiles/individual/:profileId/reservations/historical`,
        `
        ${acConfig.newFrontendUrls.reservations}/profiles/individual/:profileId/reservations/migrated`,
        `${acConfig.newFrontendUrls.reservations}/profiles/company/:profileId/reservations`,
        `
        ${acConfig.newFrontendUrls.reservations}/profiles/company/:profileId/reservations/current`,
        `
        ${acConfig.newFrontendUrls.reservations}/profiles/company/:profileId/reservations/historical`,
        `
        ${acConfig.newFrontendUrls.reservations}/profiles/company/:profileId/reservations/migrated`,
        `${acConfig.newFrontendUrls.reservations}/profiles/travel-agent/:profileId/reservations`,
        `
        ${acConfig.newFrontendUrls.reservations}/profiles/travel-agent/:profileId/reservations/current`,
        `
        ${acConfig.newFrontendUrls.reservations}/profiles/travel-agent/:profileId/reservations/historical`,
        `
        ${acConfig.newFrontendUrls.reservations}/profiles/travel-agent/:profileId/reservations/migrated`,
      ],
      translation: 'MENU.PROFILES.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: profilesPermissions.individual.viewDetails,
            source: AccessSource.Property,
          },
          {
            key: profilesPermissions.company.viewDetails,
            source: AccessSource.Property,
          },
          {
            key: profilesPermissions.travelAgent.viewDetails,
            source: AccessSource.Property,
          },
          {
            key: profileSharedPermissions.dataProtectionOfficer
              .seeProfilesDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}P`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}P`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}P`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}P`,
      keyCode: 80,
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/duplicates`,
      id: `${PROFILES_MENU_ID}-duplicates`,
      aliases: [
        `${acConfig.newFrontendUrls.profiles}/duplicates/potential`,
        `${acConfig.newFrontendUrls.profiles}/duplicates/last-merged`,

        `${acConfig.newFrontendUrls.profiles}/preview-merge/individual/:targetId/:sourceId`,
        `${acConfig.newFrontendUrls.profiles}/preview-merge/company/:targetId/:sourceId`,
        `${acConfig.newFrontendUrls.profiles}/preview-merge/travel-agent/:targetId/:sourceId`,
      ],
      translation: 'MENU.PROFILES.ITEMS.POTENTIAL_DUPLICATES',
      permissionsConfiguration: {
        permissions: [
          {
            key: profileSharedPermissions.matchAndMerge
              .viewPotentialDuplicateScreen,
            source: AccessSource.Property,
          },
          {
            key: profileSharedPermissions.matchAndMerge
              .changelogForMergedProfiles,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/individual`,
      id: `${PROFILES_MENU_ID}-create-individual-profile`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/profiles/individual/new`,
      ],
      translation: 'MENU.PROFILES.ITEMS.CREATE_INDIVIDUAL_PROFILE',
      permissionsConfiguration: {
        permissions: [
          {
            key: individualProfilePermissions.manageDetails,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/company`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/profiles/company/new`,
      ],
      translation: 'MENU.PROFILES.ITEMS.CREATE_COMPANY_PROFILE',
      id: `${PROFILES_MENU_ID}-create-company-profile`,
      permissionsConfiguration: {
        permissions: [
          {
            key: individualProfilePermissions.manageDetails,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/travel-agent`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/profiles/travel-agent/new`,
      ],
      translation: 'MENU.PROFILES.ITEMS.CREATE_TRAVELAGENT_PROFILE',
      id: `${PROFILES_MENU_ID}-create-travel-agent-profile`,
      permissionsConfiguration: {
        permissions: [
          {
            key: profilesPermissions.travelAgent.manageDetails,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/consent-and-activity`,
      translation: 'MENU.PROFILES.ITEMS.CONSENT_AND_ACTIVITY',
      id: `${PROFILES_MENU_ID}-consent-and-activity`,
      permissionsConfiguration: {
        permissions: [
          {
            key: individualProfilePermissions.manageDetails,
            source: AccessSource.Property,
          },
          {
            key: individualProfilePermissions.viewDetails,
            source: AccessSource.Property,
          },
          {
            key: individualProfilePermissions.viewIncognito,
            source: AccessSource.Property,
          },
          {
            key: individualProfilePermissions.viewConsentAndProfileActivityDashboard,
            source: AccessSource.Property,
          },
          {
            key: individualProfilePermissions.manageConsentsAndPrivacy,
            source: AccessSource.Property,
          },
          {
            key: profileSharedPermissions.dataProtectionOfficer
              .seeProfilesDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/enrollment`,
      translation: 'MENU.PROFILES.ITEMS.ENROLLMENT',
      id: `${PROFILES_MENU_ID}-enrollment`,
      allowedWhen: ({ configuration }): boolean =>
        getAllowedWhenConditionForMembershipEnrollmentFT(configuration) ||
        getAllowedWhenConditionForGenericMemberEnrollmentFT(configuration),
    },
  ],
  breadcrumbs4thSegment: [
    [
      `${RESERVATIONS_PROFILES_INDIVIDUAL_LINK}/merge/:id`,
      {
        link: 'profiles/duplicates',
        title: 'MENU.PROFILES.BREADCRUMB.4SEGMENT.PROFILES_DUPLICATES',
      },
    ],
    [
      `${RESERVATIONS_PROFILES_INDIVIDUAL_LINK}/details`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_INDIVIDUAL_LINK}/activity-reservations`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_INDIVIDUAL_LINK}/accompanying`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_INDIVIDUAL_LINK}/groups`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_INDIVIDUAL_LINK}/meetings-and-events`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_INDIVIDUAL_LINK}/analysis`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_INDIVIDUAL_LINK}/sales-activities`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_INDIVIDUAL_LINK}/reservations`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_INDIVIDUAL_LINK}/tasks`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],

    [
      `${RESERVATIONS_PROFILES_COMPANY_LINK}/details`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_COMPANY_LINK}/reservations`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_COMPANY_LINK}/activity-reservations`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_COMPANY_LINK}/groups`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_COMPANY_LINK}/meetings-and-events`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_COMPANY_LINK}/analysis`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_COMPANY_LINK}/sales-activities`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_COMPANY_LINK}/tasks`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],

    [
      `${RESERVATIONS_PROFILES_TRAVEL_AGENT_LINK}/details`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_TRAVEL_AGENT_LINK}/reservations`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_TRAVEL_AGENT_LINK}/activity-reservations`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_TRAVEL_AGENT_LINK}/groups`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_TRAVEL_AGENT_LINK}/meetings-and-events`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_TRAVEL_AGENT_LINK}/analysis`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_TRAVEL_AGENT_LINK}/sales-activities`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
    [
      `${RESERVATIONS_PROFILES_TRAVEL_AGENT_LINK}/tasks`,
      {
        link: PROFILES_DASHBOARD_LINK,
        title: PROFILES_TITLE,
      },
    ],
  ],
};

const getAllowedWhenConditionForMembershipEnrollmentFT = (
  configuration: AllAccessConfiguration
): boolean => {
  const propertyAccessSourceConfiguration =
    configuration[AccessSource.Property];
  const tenantAccessSourceConfiguration = configuration[AccessSource.Tenant];

  return (
    Boolean(
      propertyAccessSourceConfiguration.featureToggles[
        FeatureToggleName.MembershipEnrollment
      ]
    ) &&
    !tenantAccessSourceConfiguration.featureToggles[
      FeatureToggleName.GenericMemberEnrollment
    ] &&
    Boolean(
      propertyAccessSourceConfiguration.settings[
        ProfilesCustomerSettingsKeys.PerformExternalMembershipEnrollment
      ]
    ) &&
    Boolean(
      propertyAccessSourceConfiguration.permissions[
        individualProfilePermissions.enrollNewMember
      ]
    )
  );
};

const getAllowedWhenConditionForGenericMemberEnrollmentFT = (
  configuration: AllAccessConfiguration
): boolean => {
  const tenantAccessSourceConfiguration = configuration[AccessSource.Tenant];

  return (
    Boolean(
      tenantAccessSourceConfiguration.featureToggles[
        FeatureToggleName.MembershipEnrollment
      ]
    ) &&
    Boolean(
      tenantAccessSourceConfiguration.featureToggles[
        FeatureToggleName.GenericMemberEnrollment
      ]
    ) &&
    Boolean(
      tenantAccessSourceConfiguration.settings[
        ProfilesCustomerSettingsKeys.PerformExternalMembershipEnrollment
      ]
    ) &&
    Boolean(
      tenantAccessSourceConfiguration.permissions[
        individualProfilePermissions.enrollNewMember
      ]
    )
  );
};
