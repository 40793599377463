import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { handleMenuItemClick } from 'frontend-container/components/Menu/components/MegaMenu/handleMenuItemClick';
import { RightMenuItem } from 'frontend-container/components/Menu/components/MegaMenu/useRightColumnConfig';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';

import { IconName, MenuGroupSlot, MenuItemType } from '@ac/web-components';

interface Props {
  isMac: boolean;
  sortedItems: MenuElement[][];
  rightMenuItems: RightMenuItem[];
  isEditMode: boolean;
  onCloseCallback?: () => void;
  removeItemFromFavorites?: (id: string) => void;
  favoritesItemsIds: string[];
  isStandalone: boolean;
}

export const MegaMenuFavoritesList = ({
  isMac,
  sortedItems,
  rightMenuItems,
  isEditMode,
  onCloseCallback,
  favoritesItemsIds,
  removeItemFromFavorites,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const sortedItemsFlat = sortedItems.flat();

  const itemsMap = new Map<string, MenuElementItem>();

  // sortedItems used in left panel of the mega menu
  sortedItemsFlat.forEach((menu) => {
    menu.items.forEach((item) => {
      const id = `${menu.id}-${item.link}`;
      itemsMap.set(id, item);
    });
  });

  // add items from right panel of the mega menu
  rightMenuItems.forEach((menu) => {
    const menuElements = menu.menuElements;
    if (menuElements) {
      const menuElementsData = menuElements[0];
      menuElementsData.items.forEach((item) => {
        const id = `${menuElementsData.id}-${item.link}`;
        itemsMap.set(id, item);
      });
    }
  });

  const sortedFavoriteItems = favoritesItemsIds.map(
    (favoriteId: string): MenuElementItem | undefined =>
      itemsMap.get(favoriteId)
  );

  return (
    <Fragment>
      {sortedFavoriteItems.map((menuElementItem) => {
        if (menuElementItem) {
          const hotkey = (
            isMac
              ? menuElementItem?.keyboardShortcutMac
              : menuElementItem?.keyboardShortcutWindows
          )?.split('+');

          return (
            <ac-menu-item
              slot={MenuGroupSlot.items}
              link={menuElementItem?.link}
              key={menuElementItem?.link}
              id={menuElementItem?.link}
              label={t(menuElementItem.translation)}
              type={MenuItemType.favorite}
              isEditMode={isEditMode}
              onClickCallback={(): void => {
                handleMenuItemClick(menuElementItem.link, [menuElementItem]);
                onCloseCallback?.();
              }}
              hotkey={hotkey}
              rightIcon={
                menuElementItem.isExternal ? IconName.externalLink : undefined
              }
              isFavorite={true}
              onFavoriteClickCallback={removeItemFromFavorites}
            />
          );
        }

        return;
      })}
    </Fragment>
  );
};
