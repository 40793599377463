import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { reservationsMenu } from 'frontend-container/components/Menu/configuration/propertyModules/reservations/reservations';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { isFeatureToggleEnabled } from 'frontend-container/components/Menu/utils/isFeatureToggleEnabled';
import {
  MAC_SHORTCUT_PREFIX,
  MAC_SHORTCUT_PREFIX_OLD,
  WINDOWS_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX_OLD,
} from 'frontend-container/shared/constants';

import {
  FeatureToggleName,
  GeneralPropertySettingsKeys,
  propertyPermissionKeys,
  ReservationsCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const reservationPermissions = propertyPermissionKeys.reservations;
const housekeepingPermissions = propertyPermissionKeys.housekeeping;

export const FRONT_DESK_DASHBOARD_LINK = `${acConfig.newFrontendUrls.reservations}/frontdesk`;
export const INDIVIDUAL_RESERVATIONS_FRONT_DESK_DASHBOARD_LINK = `${acConfig.newFrontendUrls.individualReservations}/frontdesk`;
export const HOUSE_STATUS_LINK = `${acConfig.newFrontendUrls.reports}/house-status-dashboard`;

const FRONT_DESK_TITLE =
  'MENU.FRONTDESK.BREADCRUMB.4SEGMENT.FRONT_DESK_DASHBOARD';
const FRONT_DESK_MENU_ID = 'menu-frontdesk';

export const frontDeskMenu: MenuElement = {
  workspaceType: reservationsMenu.workspaceType,
  translation: 'MENU.FRONTDESK.TITLE',
  icon: IconName.frontdesk,
  id: FRONT_DESK_MENU_ID,
  items: [
    {
      get link(): string {
        const isReactFrontDeskDashboardEnabled = isFeatureToggleEnabled(
          FeatureToggleName.EmberToReactFrontDeskDashboardReactEnabled
        );

        return isReactFrontDeskDashboardEnabled
          ? INDIVIDUAL_RESERVATIONS_FRONT_DESK_DASHBOARD_LINK
          : FRONT_DESK_DASHBOARD_LINK;
      },
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/reservations/:id/check-in`,
        `${acConfig.newFrontendUrls.individualReservations}/frontdesk`,
      ],
      id: `${FRONT_DESK_MENU_ID}-dashboard`,
      code: 'FrontDeskDashboard',
      translation: 'MENU.FRONTDESK.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.dashboard.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}F`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}F`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}F`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}F`,
      keyCode: 70,
    },
    {
      link: HOUSE_STATUS_LINK,
      code: 'HouseStatus',
      id: `${FRONT_DESK_MENU_ID}-house-status-dashboard`,
      translation: 'MENU.FRONTDESK.ITEMS.HOUSE_STATUS_DASHBOARD',
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}2`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}S`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}2`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}S`,
      keyCode: 83,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/room-planner`,
      code: 'RoomPlanner',
      translation: 'MENU.FRONTDESK.ITEMS.ROOM_PLANNER',
      id: `${FRONT_DESK_MENU_ID}-room-planner`,
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.dashboard.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.IsRoomPlannerActive,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}5`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}L`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}5`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}L`,
      keyCode: 76,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/rooms/assignment`,
      code: 'RoomAssignment',
      id: `${FRONT_DESK_MENU_ID}-room-assignment`,
      translation: 'MENU.FRONTDESK.ITEMS.ROOM_ASSIGNMENT',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.viewReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.RoomAssignment,
          source: AccessSource.Property,
        },
      ],
      featureToggles: [
        {
          key: FeatureToggleName.RoomAssignmentDashboard,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}6`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}]`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}6`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}]`,
      keyCode: 221,
    },
    {
      link: `${acConfig.newFrontendUrls.reservations}/reservations/walk-in`,
      translation: 'MENU.FRONTDESK.ITEMS.WALK_IN',
      id: `${FRONT_DESK_MENU_ID}-walk-in`,
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.manageReservation,
            source: AccessSource.Property,
          },
          {
            key: reservationPermissions.reservation.checkInReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}0`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}/`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}0`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}/`,
      keyCode: 191,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/queue-rooms`,
      translation: 'MENU.FRONTDESK.ITEMS.QUEUE_ROOMS',
      id: `${FRONT_DESK_MENU_ID}-queue-rooms`,
      permissionsConfiguration: {
        permissions: [
          {
            key: propertyPermissionKeys.queueRooms.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.queueRooms,
          source: AccessSource.Property,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/floor-plan`,
      translation: 'MENU.FRONTDESK.ITEMS.FLOOR_PLAN',
      id: `${FRONT_DESK_MENU_ID}-floor-plan`,
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.rooms.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: GeneralPropertySettingsKeys.FloorPlan,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}2`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}2`,
      keyCode: 50,
    },
    {
      link: `${acConfig.newFrontendUrls.individualReservations}/guest-messages`,
      translation: 'MENU.FRONTDESK.ITEMS.GUEST_MESSAGES',
      id: `${FRONT_DESK_MENU_ID}-guest-messages`,
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.guestMessages.accessMessageCenter,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.GuestMessage,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}M`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}M`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}M`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}M`,
      keyCode: 77,
    },
    {
      link: `${acConfig.newFrontendUrls.individualReservations}/wake-up-calls`,
      translation: 'MENU.FRONTDESK.ITEMS.WAKE_UP_CALLS',
      id: `${FRONT_DESK_MENU_ID}-wake-up-calls`,
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.viewReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.WakeUpCalls,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}W`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}W`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}W`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}W`,
      keyCode: 87,
    },
  ],
  breadcrumbs4thSegment: [
    [
      '/reservations/reservations/:id/check-in',
      {
        get link(): string {
          const isReactFrontDeskDashboardEnabled = isFeatureToggleEnabled(
            FeatureToggleName.EmberToReactFrontDeskDashboardReactEnabled
          );

          return isReactFrontDeskDashboardEnabled
            ? INDIVIDUAL_RESERVATIONS_FRONT_DESK_DASHBOARD_LINK
            : FRONT_DESK_DASHBOARD_LINK;
        },
        title: FRONT_DESK_TITLE,
      },
    ],
    [
      '/individual-reservations/reservations/:id/check-in',
      {
        get link(): string {
          const isReactFrontDeskDashboardEnabled = isFeatureToggleEnabled(
            FeatureToggleName.EmberToReactFrontDeskDashboardReactEnabled
          );

          return isReactFrontDeskDashboardEnabled
            ? INDIVIDUAL_RESERVATIONS_FRONT_DESK_DASHBOARD_LINK
            : FRONT_DESK_DASHBOARD_LINK;
        },
        title: FRONT_DESK_TITLE,
      },
    ],
  ],
};
