import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeCashier } from 'frontend-container/components/Menu/components/Cashier/service';
import { CashierInfo } from 'frontend-container/components/Menu/types';
import { formatError } from 'frontend-container/utils/errors/formatError';
import { getLoggerInstance } from 'frontend-container/utils/logger/logger';

import { ErrorDetail } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';
import { assertIsDefined } from '@ac/library-utils/dist/utils/assertIsDefined';
import { Color, IconName, TextSize } from '@ac/web-components';

interface Props {
  onClose: () => Promise<void>;
  onConfirm: () => Promise<CashierInfo | undefined>;
  onSuccess: (locationHeaderValue: string) => Promise<void>;
  cashierName?: string;
  cashierNumber?: number;
  isCloseCashierAllowed: boolean;
}

export const CloseCashierModal: FC<Props> = (props) => {
  const { onClose, onConfirm, onSuccess, cashierName, cashierNumber } = props;
  const { t } = useTranslation();
  const [apiErrors, setApiErrors] = useState<ErrorDetail[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const confirmButtonText = apiErrors.length
    ? 'MENU.MODAL.COMMON.TRY_AGAIN'
    : 'MENU.CASHIER.CLOSE.TITLE';

  const handleSubmit = async (): Promise<void> => {
    try {
      setIsLoading(true);

      const cashierInfo = await onConfirm();
      assertIsDefined(
        cashierInfo,
        'Cashier Info is not defined - probably missing cashier number during closing cashier'
      );

      const operationLocationHeader = await closeCashier(
        cashierInfo.number,
        cashierInfo.version
      );

      await onSuccess(operationLocationHeader);
    } catch (error) {
      getLoggerInstance()?.error(
        formatError(error, 'Closing cashier was interrupt')
      );
      setApiErrors([...apiErrors, error]);
      setIsLoading(false);
    }
  };

  const isError =
    !isLoading &&
    (!props.isCloseCashierAllowed ||
      Boolean(apiErrors.length) ||
      !isDefined(cashierNumber));
  const errorMessage = !props.isCloseCashierAllowed
    ? 'MENU.CASHIER.CLOSE.ERROR.NO_PERMISSIONS'
    : 'MENU.CASHIER.CLOSE.ERROR.REQUEST_FAILED';

  return (
    <ac-modal
      confirmButton={{
        disabled: !props.isCloseCashierAllowed,
        content: {
          text: t(confirmButtonText),
        },
        onClick: handleSubmit,
      }}
      cancelButton={{
        content: {
          text: t('MENU.MODAL.COMMON.CANCEL'),
        },
        onClick: onClose,
      }}
      modalTitle={t('MENU.CASHIER.CLOSE.MODAL.TITLE')}
      loading={isLoading}
    >
      <div>
        <ac-text size={TextSize.md}>
          {t('MENU.CASHIER.CLOSE.MODAL.DESCRIPTION', { name: cashierName })}
        </ac-text>
        {isError && (
          <ac-flex class="ac-spacing-top-sm">
            <ac-validation-message
              iconName={IconName.alert}
              iconColor={Color.alert}
              label={t(errorMessage)}
            />
          </ac-flex>
        )}
      </div>
    </ac-modal>
  );
};
