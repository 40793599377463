import { Arrows } from 'frontend-container/components/Menu/components/Arrows';
import { Cashier } from 'frontend-container/components/Menu/components/Cashier/Cashier';
import { isCashierVisible } from 'frontend-container/components/Menu/components/Cashier/visibility';
import { ContextSelectButton } from 'frontend-container/components/Menu/components/ContextSelectButton/ContextSelectButton';
import {
  CroContext,
  isCroContextVisible,
} from 'frontend-container/components/Menu/components/CroContext';
import { Item } from 'frontend-container/components/Menu/components/Item';
import {
  isBellVisible,
  NotificationBellContainer,
} from 'frontend-container/components/Menu/components/NotificationBell';
import { isProfileCentersContextVisible } from 'frontend-container/components/Menu/components/ProfileCentersContext/isProfileCentersContextVisible';
import { ProfileCentersContext } from 'frontend-container/components/Menu/components/ProfileCentersContext/ProfileCentersContext';
import {
  isPropertyContextVisible,
  PropertyContext,
} from 'frontend-container/components/Menu/components/PropertyContext';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { User } from 'frontend-container/components/Menu/components/User/User';
import { useIsWorkstationVisible } from 'frontend-container/components/Menu/components/Workstation/visibility';
import { WorkstationMenu } from 'frontend-container/components/Menu/components/Workstation/Workstation';
import { WorkspaceItem } from 'frontend-container/components/Menu/hooks/useWorkspaceMenuItems';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { useScrollUpdater } from 'frontend-container/components/Menu/utils/menuScroll/useScrollUpdater';
import { isNotificationsTenantContextDashboard } from 'frontend-container/components/Menu/utils/modules/notifications';
import { isError403IpWhitlelistPage } from 'frontend-container/utils/setupWhitelistingCatcher';

import { LoginService } from '@ac/library-utils/dist/services';
import {
  DividerDirection,
  DividerSpacing,
  FlexDirection,
  JustifyContent,
} from '@ac/web-components';

interface Props {
  menuItems: MenuElement[];
  selectedItem?: string;
  selectedRoute?: string;
  onButtonHandleSelectRoute: (
    menuElementId: string,
    link: string | undefined
  ) => void;
  isRtl: boolean;
  onScrollUp: () => void;
  onScrollDown: () => void;
  scrollDownDisabled: boolean;
  scrollUpDisabled: boolean;
  buttonSelectOptions?: WorkspaceItem[];
  currentButtonContextOption?: WorkspaceItem;
}

export const MenuContent = ({
  menuItems,
  selectedItem,
  isRtl,
  scrollDownDisabled,
  scrollUpDisabled,
  selectedRoute,
  onScrollDown,
  onScrollUp,
  onButtonHandleSelectRoute,
  buttonSelectOptions,
  currentButtonContextOption,
}: Props): JSX.Element => {
  const { isWorkstationVisible } = useIsWorkstationVisible();
  const { getIsScrollNeeded, attachScrollObserverToRef } = useScrollUpdater();

  if (isError403IpWhitlelistPage()) {
    return <>{LoginService.isLoggedIn() && <User />} </>;
  }

  const areArrowsVisible = menuItems?.length > 0 && getIsScrollNeeded();

  return (
    <>
      {menuItems && menuItems.length ? (
        <ac-flex
          grow={true}
          class="items-container"
          id="menu-items-container"
          direction={FlexDirection.column}
          ref={attachScrollObserverToRef}
        >
          {menuItems.map((menuItem) => (
            <Item
              key={menuItem.id}
              isSelected={menuItem.id === selectedItem}
              selectedRoute={selectedRoute}
              menuElement={menuItem}
              setSelectedRoute={onButtonHandleSelectRoute}
            />
          ))}
        </ac-flex>
      ) : null}
      {areArrowsVisible && !isNotificationsTenantContextDashboard() && (
        <Arrows
          isRtl={isRtl}
          onScrollUp={onScrollUp}
          onScrollDown={onScrollDown}
          scrollDownDisabled={scrollDownDisabled}
          scrollUpDisabled={scrollUpDisabled}
        />
      )}

      <>
        <ac-box sizeSm="auto" shrink={false} grow>
          <ac-flex justifyContent={JustifyContent.flexEnd}>
            <ContextSelectButton
              buttonSelectOptions={buttonSelectOptions}
              currentButtonOption={currentButtonContextOption}
            />
          </ac-flex>
        </ac-box>
        {isPropertyContextVisible() && <PropertyContext />}
        {isCroContextVisible() && !isNotificationsTenantContextDashboard() && (
          <CroContext />
        )}
        {isProfileCentersContextVisible() && <ProfileCentersContext />}
        {isCashierVisible() && <Cashier />}
        {isWorkstationVisible && (
          <>
            <WorkstationMenu />
            <ac-divider
              direction={DividerDirection.vertical}
              spacing={DividerSpacing.none}
            />
          </>
        )}
        {isBellVisible() && <NotificationBellContainer />}
      </>

      {LoginService.isLoggedIn() && userService.getCurrentUserDetails() && (
        <User />
      )}
    </>
  );
};
