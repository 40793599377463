import { MenuAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { NonEmptyArray } from 'frontend-container/utils/types';

import { IconName } from '@ac/web-components';

type BreadcrumbsOptionalSegment = {
  link: string;
  title: string;
};

export enum Workspace {
  PROFILE_CENTER = 'ProfileCenter',
  PROPERTY = 'Property',
  CRO = 'CentralReservationOffice',
  CONFIGURATION = 'Configuration',
  // system user, custom menu elements, e.g favorites, external items
  NONE = 'None',
}

export type WorkspaceWithMenuItems =
  | Workspace.CONFIGURATION
  | Workspace.CRO
  | Workspace.PROFILE_CENTER
  | Workspace.PROPERTY;

export interface MenuElementItem
  extends MenuAccessConfiguration<MenuElementItem> {
  link: string;
  id: string;
  code?: string;
  aliases?: NonEmptyArray<string>;
  translation: string;
  keyboardShortcutWindows?: string;
  keyboardShortcutWindowsOld?: string;
  keyboardShortcutMac?: string;
  keyboardShortcutMacOld?: string;
  keyCode?: number;
  isExternal?: boolean;
  isUnderlined?: boolean;
}

export interface MenuElement extends MenuAccessConfiguration<MenuElement> {
  translation: string;
  icon: IconName;
  id: string;
  shift?: number;
  items: MenuElementItem[];
  workspaceType: () => Workspace;
  breadcrumbs4thSegment?: Array<[string, BreadcrumbsOptionalSegment]>;
  hideInGlobalSearch?: boolean;
}

export interface CashierInfo {
  number: number;
  version?: number;
  name?: string;
}
