import { acConfig } from '@ac/library-utils/dist/declarations';

export const isPointOfSale = (
  pathname: string = window.location.pathname
): boolean => {
  return (
    !!acConfig.newFrontendUrls.pointOfSale &&
    pathname?.startsWith(acConfig.newFrontendUrls.pointOfSale)
  );
};

export default {
  isPointOfSale,
};
