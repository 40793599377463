import {
  FC,
  LegacyRef,
  MouseEvent as ReactMouseEvent,
  MouseEventHandler,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FavoriteIcon } from 'frontend-container/components/Menu/components/FavoriteIcon/FavoriteIcon';
import { Shortcut } from 'frontend-container/components/Menu/components/Shortcut/Shortcut';
import { MenuElementItem } from 'frontend-container/components/Menu/types';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';
import { parseMacShortcutsToViewMode } from 'frontend-container/components/Menu/utils/parseMacShortcusToViewMode';
import { useFavoriteMenuItems } from 'frontend-container/components/Menu/utils/useFavoriteMenuItems';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import {
  ColorsPalette,
  DividerColor,
  DividerSpacing,
  FlexGap,
  IconName,
  Size,
  StopPropagationType,
} from '@ac/web-components';

import './MenuLinks.scss';

interface Props {
  wrapperClasses: string;
  onClick: (link: string) => void;
  onMouseOver?: MouseEventHandler<HTMLElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
  item: MenuElementItem;
  active: boolean;
  id: string;
  showActionArrow?: boolean;
  spacing?: FlexGap;
  itemRef?: LegacyRef<HTMLDivElement>;
  itemProps?: Record<string, unknown>;
  badgeContent?: string;
  favoriteIcon?: boolean;
  onFavoriteIconClickHandler?: (link: string) => void;
  isShortcutWithThen?: boolean;
}

export const InternalMenuLink: FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const favorites = useFavoriteMenuItems();
  const isMac = navigator.appVersion.indexOf('Mac') !== -1;
  const propertyId = SessionService.getPropertyId() ?? '';
  const fullLink = props.item.link
    .replace(/:id/g, propertyId)
    .replace(/:customerId/g, LoginService.authData()?.tenantId ?? '');

  const shortcutsClassNames = [
    'subitem-shortcut',
    !props.active ? 'not-selected' : undefined,
  ]
    .filter(Boolean)
    .join(' ');

  const handleOnClick = (
    event: ReactMouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.preventDefault();
    if (!props.showActionArrow) {
      props.onClick(props.item.link);
    }
  };

  const label = useMemo(
    () =>
      i18n.exists(props.item.translation)
        ? t(props.item.translation)
        : props.item.translation,
    [i18n, props.item.translation, t]
  );

  const isMenuV2Enabled = getIsMenuV2Enabled();

  const macShortcut = isMenuV2Enabled
    ? props.item.keyboardShortcutMac
    : props.item.keyboardShortcutMacOld;
  const windowsShortcut = isMenuV2Enabled
    ? props.item.keyboardShortcutWindows
    : props.item.keyboardShortcutWindowsOld;

  const keyboardShortcut = isMac
    ? parseMacShortcutsToViewMode(macShortcut)
    : windowsShortcut;

  const linkProps = props.itemProps ?? {};
  const isItemFavorite =
    favorites?.items.map((item) => item.link).includes(props.item.link) ||
    false;

  return (
    <>
      {props.item.itemConfiguration?.hasTopDivider && (
        <ac-box class="ac-padding-horizontal-sm">
          <ac-divider color={DividerColor.gray6} spacing={DividerSpacing.xss} />
        </ac-box>
      )}
      <div
        className={props.wrapperClasses}
        onClick={handleOnClick}
        onMouseOver={props.onMouseOver}
        onMouseLeave={props.onMouseLeave}
        ref={props.itemRef}
        data-test-selector={props.id}
        id={props.id}
        {...linkProps}
      >
        <a
          className={`internal-link ac-gap-${props.spacing ?? 'xlg'}`}
          target="_blank"
          rel="noopener noreferrer"
          href={fullLink}
        >
          <div>
            {label}
            {props.badgeContent && (
              <ac-badge
                content={props.badgeContent}
                color={ColorsPalette.fog100.hex}
                class="ac-margin-inline-start-sm"
              />
            )}
          </div>
          <ac-flex columnGap={FlexGap.sm}>
            {props.favoriteIcon && (
              <ac-stop-propagation
                type={StopPropagationType.preventDefault}
                onClickHandler={() =>
                  props.onFavoriteIconClickHandler?.(props.item.link)
                }
              >
                <FavoriteIcon
                  id={`favorite-icon-${props.item.id}`}
                  isFavorite={isItemFavorite}
                />
              </ac-stop-propagation>
            )}
            {keyboardShortcut &&
              (props.isShortcutWithThen ? (
                <Shortcut text={keyboardShortcut} />
              ) : (
                <span className={shortcutsClassNames}>{keyboardShortcut}</span>
              ))}
            {props.showActionArrow && (
              <ac-icon
                icon={IconName.actionRight}
                size={isMenuV2Enabled ? Size.xs : Size.sm}
              />
            )}
          </ac-flex>
        </a>
      </div>
    </>
  );
};
