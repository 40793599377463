import { CSSProperties, SyntheticEvent, useMemo } from 'react';
import { ContextDropdownDescription } from 'frontend-container/components/Menu/components/Context/components/ContextDropdown/ContextDropdownDescription';
import {
  CSS_VAR_MENU_BAR_COLOR,
  MENU_V2_ID,
} from 'frontend-container/components/Menu/constants';
import { getCssVariable } from 'frontend-container/components/Menu/utils/getCssVariable';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';

import { isDarkBackground } from '@ac/library-utils/dist/utils';
import { useSharedTooltipPresenter } from '@ac/react-infrastructure';
import {
  AlignItems,
  Color,
  JustifyContent,
  Placement,
  Size,
  TargetValueObject,
  TextColor,
} from '@ac/web-components';

import { Context } from '../../context';

import './ContextDropdown.scss';

interface Props {
  id?: string;
  selectedContext?: Context;
  businessDate?: string;
  dateFormat?: string;
  propertyTime?: string;
  color?: string | Color | TextColor;
  isListVisible?: boolean;
  onClick?: (event?: Event | SyntheticEvent<Element, Event>) => void;
  menuV2?: boolean;
  toShowOnlyIconInDropDown?: boolean;
  tooltipContent?: string;
}

export const ContextDropdown = ({
  id = 'context-dropdown-button',
  selectedContext,
  businessDate,
  dateFormat,
  propertyTime,
  color,
  isListVisible,
  onClick,
  toShowOnlyIconInDropDown = false,
  tooltipContent,
}: Props): JSX.Element => {
  const { createShowHandler } = useSharedTooltipPresenter({
    targetValue: TargetValueObject.mainMenu,
  });

  const menuColor = color || getCssVariable(CSS_VAR_MENU_BAR_COLOR);

  const textColor = useMemo(() => {
    return isDarkBackground(menuColor) ? TextColor.white : TextColor.black;
  }, [menuColor]);

  const iconColor = textColor === TextColor.white ? Color.white : Color.black;
  const isMenuV2Enabled = getIsMenuV2Enabled();

  const classes = [
    'context-dropdown',
    isListVisible ? 'context-dropdown-active' : '',
    isMenuV2Enabled ? 'context-dropdown-menu-v2' : '',
    isMenuV2Enabled && !color ? 'context-dropdown-menu-v2-default-color' : '',
  ]
    .filter(Boolean)
    .join(' ');

  const code = selectedContext?.code ?? '';

  const tooltipShowHandler = tooltipContent
    ? createShowHandler({
        text: tooltipContent,
        boundaryContainer: MENU_V2_ID,
        placement: Placement.bottomCenter,
      })
    : undefined;

  if (isMenuV2Enabled) {
    return (
      <button
        id={id}
        className={classes}
        onClick={onClick}
        onMouseEnter={tooltipShowHandler}
      >
        <ac-flex
          class="context-dropdown-content context-dropdown-min-height ac-gap-sm"
          alignItems={AlignItems.center}
        >
          <ac-flex
            class="context-dropdown-icon-container"
            justifyContent={JustifyContent.center}
            alignItems={AlignItems.center}
            style={{
              '--context-color': menuColor,
            }}
          >
            <ac-icon
              icon={selectedContext?.icon}
              color={iconColor}
              size={Size.lg}
            />
          </ac-flex>
          {!toShowOnlyIconInDropDown && (
            <ContextDropdownDescription
              businessDate={businessDate}
              dateFormat={dateFormat}
              propertyTime={propertyTime}
              textColor={isMenuV2Enabled ? TextColor.white : textColor}
              code={code}
            />
          )}
        </ac-flex>
      </button>
    );
  }

  return (
    <button
      id={id}
      className={classes}
      style={
        {
          '--context-color': menuColor,
        } as CSSProperties
      }
      onClick={onClick}
    >
      <ac-flex
        class="context-dropdown-content ac-gap-sm"
        alignItems={AlignItems.center}
      >
        {selectedContext && (
          <ac-icon
            icon={selectedContext.icon}
            color={Color.foregroundPrimary}
            size={Size.lg}
          />
        )}
        <ContextDropdownDescription
          businessDate={businessDate}
          dateFormat={dateFormat}
          propertyTime={propertyTime}
          textColor={textColor}
          code={code}
        />
      </ac-flex>
    </button>
  );
};
