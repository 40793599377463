import { isPropertyRatesModule } from 'frontend-container/components/Menu/utils/modules/propertyRates';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const propertyRatesAppName = 'frontend-property-rates';
export const registerPropertyRates = (): void => {
  registerApplication(
    propertyRatesAppName,
    () =>
      loadReactApp(acConfig.frontendUrls.propertyRates, propertyRatesAppName),
    (location: Location) => isPropertyRatesModule(location.pathname)
  );
};
