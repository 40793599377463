import { App } from 'frontend-container/components/App';
import { setIdleTimeout as resetAutomaticLogoutTimers } from 'frontend-container/components/AutomaticLogout/idleTimeout';
import { Error as ErrorComponent } from 'frontend-container/components/Errors/Error';
import {
  croLandingPagePathname,
  propertyLandingPagePathname,
} from 'frontend-container/components/LandingPage';
import { profileCenterLandingPagePathname } from 'frontend-container/components/LandingPage/location';
import { removeLoginBrandingFromStorage } from 'frontend-container/components/Menu/components/Branding/brandingLocalStorage';
import { ContextType } from 'frontend-container/components/Menu/components/Context';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { userPreferencesService } from 'frontend-container/components/Menu/components/User/UserPreferences/service';
import { THEME_DARK_KEY } from 'frontend-container/components/Menu/service';
import { isGlobalRegion } from 'frontend-container/components/Menu/utils/isGlobalRegion';
import {
  getAccessDeniedRedirectPath,
  getShouldRedirectToAccessDenied,
} from 'frontend-container/components/Prestep/AccessDenied/accessDeniedRedirection';
import {
  getShouldRedirectToPrestep,
  initPrestep,
} from 'frontend-container/components/Prestep/initPrestep';
import { isReadOnlyRequired } from 'frontend-container/components/ReadOnlyMode/isReadOnlyRequired';
import {
  getReadOnlyByUser,
  setReadOnlyMode,
} from 'frontend-container/components/ReadOnlyMode/setReadOnlyMode';
import {
  registerApplications,
  registerExternalPages,
  registerNoPermissionsPages,
} from 'frontend-container/config';
import { isExternalPage } from 'frontend-container/externalPages/externalPagesConfig';
import {
  getCustomerContextData,
  getPropertyContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';
import {
  setBusinessContextForApp,
  setSafeBusinessContextForApp,
} from 'frontend-container/shared/businessContext/setBusinessContextForApp';
import { setInitialBusinessContextForUnit } from 'frontend-container/shared/businessContext/setInitialBusinessContextForUnit';
import {
  CONTAINER_ROOT_SELECTOR,
  ROUTER_OUTLET_SELECTOR,
} from 'frontend-container/shared/constants';
import { navigateToPage } from 'frontend-container/shared/navigation/navigateToPage';
import { LocalStorage } from 'frontend-container/shared/storage/localStorage';
import {
  createReactRoot,
  removeReactRoot,
} from 'frontend-container/utils/createReactRoot';
import {
  appChange,
  beforeAppChange,
  beforeEveryAppMount,
} from 'frontend-container/utils/externalResources/shared';
import { isLoginUrl, isMainUrl } from 'frontend-container/utils/location';
import { getLoggerInstance } from 'frontend-container/utils/logger/logger';
import { preventMoreThenOneOverrideOfEventListenersFunctions } from 'frontend-container/utils/preventMoreThenOneOverrideOfEventListenersFunctions';
import { redirectToContextRegion } from 'frontend-container/utils/region/redirectToContextRegion';
import { setupToastNotificationsTimeouts } from 'frontend-container/utils/setupToastNotificationsTimeouts';
import { start as startSingleSpa } from 'single-spa';

import { NoUnitAssignmentError, UserPreferencesDetails } from '@ac/library-api';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';
import { Errors } from '@ac/web-components';

/**
 * At this point wbc, authorization, api, logger and tracker are ready.
 * We have a loader on the screen and we are focused on
 * Container components and everything assosiciated with them
 */
export const startContainerApp = async (newRoute: string): Promise<void> => {
  try {
    removeLoginBrandingFromStorage();
    setupTheme();
    setupReadOnlyMode();
    resetAutomaticLogoutTimers(null);

    await setInitialBusinessContextForUnit();
    await userPreferencesService.validateUserPreferences();
    await renderInitialContent(newRoute);
  } catch (e) {
    renderContainerInitializationError(e);
  }
};

const renderInitialContent = async (newRoute: string): Promise<void> => {
  if (isExternalPage()) {
    registerExternalPages();
    startSingleSpa();

    return;
  }

  const isSuperUser = LoginService.isSuperUser();

  if (isSuperUser) {
    return afterPrestep(newRoute);
  }

  const shouldRedirectToAccessDenied = getShouldRedirectToAccessDenied();

  if (shouldRedirectToAccessDenied) {
    registerNoPermissionsPages();
    startSingleSpa();

    const accessDeniedPath = getAccessDeniedRedirectPath();
    navigateToPage(accessDeniedPath);

    return;
  }

  const shouldRedirectToPrestep = getShouldRedirectToPrestep(newRoute);

  if (shouldRedirectToPrestep) {
    await userPreferencesService.initializeDefaultLanguage(undefined);

    return initPrestep(newRoute, afterPrestep);
  }

  return afterPrestep(newRoute);
};

const afterPrestep = async (
  newRoute: string,
  prestepUserPreferences?: UserPreferencesDetails
): Promise<void> => {
  await userPreferencesService.initializeUserPreferences(
    newRoute,
    prestepUserPreferences
  );

  const redirected = setupWorkspaceRegionRedirect(
    prestepUserPreferences,
    newRoute
  );

  if (redirected) {
    return;
  }

  const preferences = userService.getCurrentUserPreferences()?.preferences;
  const isNewMenuActive = Boolean(preferences?.displayNewMenu);

  const startingUnit = isNewMenuActive
    ? {
        tenantId: LoginService.authData()?.tenantId,
        centralReservationOfficeId:
          SessionService.getCentralReservationOfficeId(),
        propertyId: SessionService.getPropertyId(),
        profileCenterId: SessionService.getProfileCenterId(),
      }
    : undefined;

  await setBusinessContextForApp(startingUnit);

  beforeEveryAppMount(setSafeBusinessContextForApp);
  setupToastTimeouts();
  renderContainerApp();
};

const renderContainerApp = (): void => {
  registerApplications();
  window.addEventListener('single-spa:before-app-change', beforeAppChange);
  window.addEventListener('single-spa:app-change', appChange);

  preventMoreThenOneOverrideOfEventListenersFunctions();
  removeReactRoot(ROUTER_OUTLET_SELECTOR);
  startSingleSpa();

  const containerRoot = createReactRoot(CONTAINER_ROOT_SELECTOR);
  containerRoot.render(<App />);
};

const renderContainerInitializationError = (error: unknown): void => {
  const message = error instanceof Error ? error.message : String(error);
  const logMessage = `error on initializing app, ${message}`;
  getLoggerInstance()?.error(logMessage);

  removeReactRoot(ROUTER_OUTLET_SELECTOR);

  const routerOutletRoot = createReactRoot(ROUTER_OUTLET_SELECTOR);
  const errorType =
    error instanceof NoUnitAssignmentError ? Errors.error403 : Errors.error500;

  routerOutletRoot.render(<ErrorComponent errorType={errorType} />);
};

const setupTheme = (): void => {
  const { search } = window.location;

  if (search.startsWith('?themeDark')) {
    LocalStorage.set(THEME_DARK_KEY, true);
  }
  if (search.startsWith('?themeLight')) {
    LocalStorage.set(THEME_DARK_KEY, false);
  }
};

const setupReadOnlyMode = (): void => {
  const currentRegionCode = getCurrentRegionCode();
  if (isGlobalRegion(currentRegionCode)) {
    setReadOnlyMode(false);
  }

  if (isReadOnlyRequired()) {
    const newValue = getReadOnlyByUser() ?? true;
    setReadOnlyMode(newValue);
  }
};

const setupWorkspaceRegionRedirect = (
  userPreferences?: UserPreferencesDetails,
  newRoute?: string
): boolean => {
  const userPreferencesPropertyId = userPreferences?.defaultPropertyId;

  if (
    userPreferencesPropertyId &&
    userPreferences.defaultWorkspace === ContextType.PROPERTY
  ) {
    const redirectRoute =
      isMainUrl(newRoute) || isLoginUrl(newRoute)
        ? propertyLandingPagePathname
        : newRoute;

    return redirectToContextRegion(
      ContextType.PROPERTY,
      userPreferencesPropertyId,
      redirectRoute
    );
  }

  const userPreferencesCroId =
    userPreferences?.defaultCentralReservationOfficeId;

  if (
    userPreferencesCroId &&
    userPreferences.defaultWorkspace === ContextType.CRO
  ) {
    const redirectRoute =
      isMainUrl(newRoute) || isLoginUrl(newRoute)
        ? croLandingPagePathname
        : newRoute;

    return redirectToContextRegion(
      ContextType.CRO,
      userPreferencesCroId,
      redirectRoute
    );
  }

  const userPreferencesProfileCenterId =
    userPreferences?.defaultProfileCenterId;

  if (
    userPreferencesProfileCenterId &&
    userPreferences.defaultWorkspace === ContextType.PROFILE_CENTER
  ) {
    const redirectRoute =
      isMainUrl(newRoute) || isLoginUrl(newRoute)
        ? profileCenterLandingPagePathname
        : newRoute;

    return redirectToContextRegion(
      ContextType.PROFILE_CENTER,
      userPreferencesProfileCenterId,
      redirectRoute
    );
  }

  return false;
};

const setupToastTimeouts = (): void => {
  const propertyContext = getPropertyContextData();
  const customerContext = getCustomerContextData();

  if (propertyContext) {
    setupToastNotificationsTimeouts({
      propertySettings: propertyContext.settings.effectiveSettingsDetails ?? [],
      customerSettings: customerContext?.settings.effectiveSettings ?? [],
    });
  } else {
    setupToastNotificationsTimeouts({
      propertySettings: [],
      customerSettings: [],
    });
  }
};
