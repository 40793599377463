import { PureComponent } from 'react';
import { AlternateDomainSwitching } from 'frontend-container/components/AlternateDomainSwitching/AlternateDomainSwitching';
import { AutomaticLogout } from 'frontend-container/components/AutomaticLogout/AutomaticLogout';
import { ContextHelpSidePanel } from 'frontend-container/components/ContextHelp/ContextHelpSidePanel/ContextHelpSidePanel';
import { pathname404 } from 'frontend-container/components/Errors/Error404';
import { Branding } from 'frontend-container/components/Menu/components/Branding/Branding';
import { DocumentTitle } from 'frontend-container/components/Menu/components/DocumentTitle';
import { GlobalSearchModal } from 'frontend-container/components/Menu/components/GlobalSearchModal/GlobalSearchModal';
import { GlobalSearchModalPresenterContextProvider } from 'frontend-container/components/Menu/components/GlobalSearchModal/presenter/Provider';
import { MegaMenu } from 'frontend-container/components/Menu/components/MegaMenu/MegaMenu';
import { MegaMenuPresenterContextProvider } from 'frontend-container/components/Menu/components/MegaMenu/presenter/Provider';
import { UnsavedChangesModal } from 'frontend-container/components/Menu/components/UnsavedChangesModal/UnsavedChangesModal';
import { Menu } from 'frontend-container/components/Menu/MenuV1/MenuV1';
import { MenuV2 } from 'frontend-container/components/Menu/MenuV2';
import { shouldUseDarkTheme } from 'frontend-container/components/Menu/service';
import { FavoriteListContextProvider } from 'frontend-container/components/Menu/store/favorites/Provider';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';
import { RefreshModal } from 'frontend-container/components/modals/refreshModal/RefreshModal';
import { MultiRegionErrorHandler } from 'frontend-container/components/MultiRegionError/MultiRegionErrorHandler';
import { Panels } from 'frontend-container/components/panels/Panels';
import { PanelPresenterProviders } from 'frontend-container/components/panels/Providers';
import { RouterContextProvider } from 'frontend-container/components/Router/context/Provider';
import { Router } from 'frontend-container/components/Router/Router';
import { SessionDataSnapshotUpdater } from 'frontend-container/components/SessionData/SessionDataSnapshotUpdater';
import { Toast } from 'frontend-container/components/Toast';
import { TokenExpirationGuard } from 'frontend-container/components/TokenExpirationGuard/TokenExpirationGuard';
import { replaceWithPage } from 'frontend-container/shared/navigation/replaceWithPage';
import { isLoginUrl, isMainUrl } from 'frontend-container/utils/location';
import { checkActivityFunctions } from 'single-spa';

import {
  createProvider,
  FrontendCommunicationContextProvider,
  PropsWithChildren,
  ProviderComposer,
  RoutingContextProvider,
  SharedInfotipProvider,
  SharedTooltipProvider,
  ViewportContextProvider,
  withTranslationsReady,
} from '@ac/react-infrastructure';

import './App.scss';
import './AppDarkTheme.scss';
import './AppMediaPrint.scss';

const providers = [
  createProvider({ component: FrontendCommunicationContextProvider }),
  createProvider({ component: PanelPresenterProviders }),
  createProvider({ component: SharedInfotipProvider }),
  createProvider({ component: SharedTooltipProvider }),
  createProvider({ component: RoutingContextProvider }),
  createProvider({ component: ViewportContextProvider }),
  createProvider({ component: RouterContextProvider }),
  createProvider({ component: FavoriteListContextProvider }),
];

const menuV2Providers = [
  createProvider({ component: GlobalSearchModalPresenterContextProvider }),
  createProvider({ component: MegaMenuPresenterContextProvider }),
];

const AppProviders = (props: PropsWithChildren): JSX.Element => (
  <ProviderComposer providers={providers}>{props.children}</ProviderComposer>
);

const MenuV2ContextProviders = (props: PropsWithChildren): JSX.Element => (
  <ProviderComposer providers={menuV2Providers}>
    {props.children}
  </ProviderComposer>
);

class App extends PureComponent {
  public componentDidMount(): void {
    const appsThatShouldBeActive = checkActivityFunctions(window.location);
    if (!appsThatShouldBeActive.length && !isMainUrl() && !isLoginUrl()) {
      // todo: temporary console use, remove after confirmation of problem
      // eslint-disable-next-line no-console
      console.error(
        'WRONG LOCATION: ',
        window.location.pathname,
        appsThatShouldBeActive
      );
      replaceWithPage(pathname404);

      return;
    }

    const useDarkTheme = shouldUseDarkTheme();

    document.documentElement.style.setProperty('--menu-width', '0px');

    if (useDarkTheme) {
      document.body.classList.add('main-app-dark');
    }
  }

  isMenuV2Enabled = getIsMenuV2Enabled();

  public render(): JSX.Element | null {
    return (
      <AppProviders>
        <Router />
        {this.isMenuV2Enabled ? (
          <MenuV2ContextProviders>
            <MenuV2 />
            <GlobalSearchModal />
            <MegaMenu />
          </MenuV2ContextProviders>
        ) : (
          <Menu />
        )}
        <TokenExpirationGuard />
        <RefreshModal />
        <DocumentTitle />
        <Branding />
        <SessionDataSnapshotUpdater />
        <MultiRegionErrorHandler />
        <AutomaticLogout />
        <ContextHelpSidePanel />
        <UnsavedChangesModal />
        <Toast />
        <AlternateDomainSwitching />

        <Panels />
      </AppProviders>
    );
  }
}

const AppWithTranslationsReady = withTranslationsReady(App);

export { AppWithTranslationsReady as App };
