import { acConfig } from '@ac/library-utils/dist/declarations';

export const isGuestOrderingConfiguration = (
  pathname: string = window.location.pathname
): boolean => {
  return (
    !!acConfig.newFrontendUrls.guestOrderingConfiguration &&
    pathname?.startsWith(acConfig.newFrontendUrls.guestOrderingConfiguration)
  );
};

export default {
  isGuestOrderingConfiguration,
};
