import { isPointOfSale } from 'frontend-container/components/Menu/utils/modules/pointOfSale';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const pointOfSaleAppName = 'point-of-sale';

export const registerPointOfSale = (): void => {
  registerApplication(
    pointOfSaleAppName,
    () => loadReactApp(acConfig.frontendUrls.pointOfSale, pointOfSaleAppName),
    (location: Location) => isPointOfSale(location.pathname)
  );
};
