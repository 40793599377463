import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import { isIntegrationsModuleWithProperty } from 'frontend-container/components/Menu/utils/modules/integrations';
import {
  MAC_SHORTCUT_PREFIX,
  MAC_SHORTCUT_PREFIX_OLD,
  WINDOWS_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX_OLD,
} from 'frontend-container/shared/constants';

import { FeatureToggleName, propertyPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const integrationsPermissions = propertyPermissionKeys.integrations;

const INTEGRATIONS_MENU_ID = 'menu-integrations';

export const integrationsMenu: MenuElement = {
  workspaceType: () => {
    if (isIntegrationsModuleWithProperty()) {
      return Workspace.PROPERTY;
    }

    return Workspace.CONFIGURATION;
  },
  translation: 'MENU.INTEGRATIONS.TITLE',
  icon: IconName.structure,
  id: INTEGRATIONS_MENU_ID,
  items: [
    {
      link: `${acConfig.newFrontendUrls.integrations}/dashboard`,
      id: `${INTEGRATIONS_MENU_ID}-dashboard`,
      aliases: [acConfig.newFrontendUrls.integrations],
      translation: 'MENU.INTEGRATIONS.ITEMS.INTERFACES',
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}N`,
      keyboardShortcutWindowsOld: `${WINDOWS_SHORTCUT_PREFIX_OLD}\\`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}N`,
      keyboardShortcutMacOld: `${MAC_SHORTCUT_PREFIX_OLD}\\`,
      keyCode: 220,
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/pay-by-links`,
      id: `${INTEGRATIONS_MENU_ID}-pay-by-links`,
      translation: 'MENU.INTEGRATIONS.ITEMS.PAY_BY_LINKS',
      permissionsConfiguration: {
        permissions: [
          {
            key: integrationsPermissions.createPaybyPayment,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/webhook-management`,
      translation: 'MENU.INTEGRATIONS.ITEMS.WEBHOOK',
      id: `${INTEGRATIONS_MENU_ID}-webhook`,
      permissionsConfiguration: {
        permissions: [
          {
            key: integrationsPermissions.webhookManagementViewSubscription,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/:id/data-migration/dashboard`,
      aliases: [acConfig.newFrontendUrls.integrations],
      id: `${INTEGRATIONS_MENU_ID}-migration-dashboard`,
      translation: 'MENU.INTEGRATIONS.ITEMS.DATA_MIGRATION',
      permissionsConfiguration: {
        permissions: [
          {
            key: integrationsPermissions.dataMigrationViewDashboard,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/migration-authorization`,
      translation: 'MENU.INTEGRATIONS.ITEMS.MIGRATION_AUTHORIZATION_REQUESTS',
      id: `${INTEGRATIONS_MENU_ID}-auth-requests`,
      featureToggles: [
        {
          key: FeatureToggleName.MigrationRestrictedExecution,
          source: AccessSource.Tenant,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/grs-management/dashboard`,
      translation: 'MENU.INTEGRATIONS.ITEMS.GRS_MANAGEMENT',
      id: `${INTEGRATIONS_MENU_ID}-grs-management-dashboard`,
      featureToggles: [
        {
          key: FeatureToggleName.IntegrationIHG,
          source: AccessSource.Tenant,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/central-integration-manager/dashboard`,
      translation: 'MENU.INTEGRATIONS.ITEMS.CENTRAL_INTEGRATION_MANAGER',
      id: `${INTEGRATIONS_MENU_ID}-central-integration-manager-dashboard`,
      featureToggles: [
        {
          key: FeatureToggleName.CentralIntegrationManagerPhase1,
          source: AccessSource.Tenant,
        },
      ],
    },
  ],
  breadcrumbs4thSegment: [
    [
      '/integrations/webhook-management/new',
      {
        link: 'integrations/webhook-management',
        title: 'MENU.INTEGRATIONS.BREADCRUMB.4SEGMENT.WEBHOOK_MANAGEMENT',
      },
    ],
    [
      '/integrations/webhook-management/:id',
      {
        link: 'integrations/webhook-management',
        title: 'MENU.INTEGRATIONS.BREADCRUMB.4SEGMENT.WEBHOOK_MANAGEMENT',
      },
    ],
  ],
};

export const integrationsMenuForSystemUser: MenuElement = {
  workspaceType: () => Workspace.CONFIGURATION,
  translation: 'MENU.INTEGRATIONS.TITLE',
  icon: IconName.structure,
  id: INTEGRATIONS_MENU_ID,
  items: [
    {
      link: `${acConfig.newFrontendUrls.integrations}/central-integration-manager/dashboard`,
      translation: 'MENU.INTEGRATIONS.ITEMS.CENTRAL_INTEGRATION_MANAGER',
      id: `${INTEGRATIONS_MENU_ID}-central-integration-manager-dashboard`,
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/migration-authorization/system-user-dashboard`,
      translation: 'MENU.INTEGRATIONS.ITEMS.DATA_MIGRATION_AUTHORIZATION',
      id: `${INTEGRATIONS_MENU_ID}-data-migration-auth`,
    },
  ],
};
