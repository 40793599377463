import { useCallback, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { handleUnsavedChangesRedirect } from 'frontend-container/components/Menu/components/Item/handleUnsavedChangesRedirect';
import {
  getMainApplicationMenu,
  getMenuV1MainApplicationMenu,
} from 'frontend-container/components/Menu/configuration';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';

const handleOnClickMenuSubItem = async (
  discardUnsavedChanges: boolean,
  link: string,
  callback: (link: string) => void
): Promise<void> =>
  await handleUnsavedChangesRedirect(discardUnsavedChanges, link, callback);

const MAX_TIMEOUT = 1500;
export const useMenuShortcuts = (
  discardUnsavedChangesOnRedirect: boolean,
  redirectCallback: (link: string) => void
) => {
  const isMenuV2Enabled = getIsMenuV2Enabled();
  const menuElements = isMenuV2Enabled
    ? getMainApplicationMenu()
    : getMenuV1MainApplicationMenu();

  const inputSequenceRef = useRef<string[]>([]);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const resetSequence = () => {
    inputSequenceRef.current = [];
  };

  const isMac = navigator.appVersion.indexOf('Mac') !== -1;

  const shortcutsWindows = menuElements.flatMap((element: MenuElement) =>
    element.items.filter((item) => item.keyboardShortcutWindows)
  );
  const shortcutsMac = menuElements.flatMap((element: MenuElement) =>
    element.items.filter((item) => item.keyboardShortcutMac)
  );

  const keyboardShortcuts = (
    isMac
      ? shortcutsMac.map((item: MenuElementItem) =>
          item.keyboardShortcutMac?.toLowerCase()
        )
      : shortcutsWindows.map((item: MenuElementItem) =>
          item.keyboardShortcutWindows?.toLowerCase()
        )
  ).filter(Boolean);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const pressedKey = event.key;

      inputSequenceRef.current =
        inputSequenceRef.current.length === 2
          ? [inputSequenceRef.current[1], pressedKey]
          : [...inputSequenceRef.current, pressedKey];

      const currentSequence = inputSequenceRef.current.join('+')?.toLowerCase();

      if (keyboardShortcuts.some((sequence) => currentSequence === sequence)) {
        const shortcuts = isMac ? shortcutsMac : shortcutsWindows;

        const foundMenuItem = shortcuts.find((item: MenuElementItem) => {
          const itemShortcut = isMac
            ? item.keyboardShortcutMac?.toLowerCase()
            : item.keyboardShortcutWindows?.toLowerCase();
          const isTheSameKeyCode = currentSequence === itemShortcut;

          return isTheSameKeyCode;
        });

        if (foundMenuItem) {
          handleOnClickMenuSubItem(
            discardUnsavedChangesOnRedirect,
            foundMenuItem.link,
            redirectCallback
          );
        }
        resetSequence();
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(resetSequence, MAX_TIMEOUT);
    },
    [
      redirectCallback,
      discardUnsavedChangesOnRedirect,
      isMac,
      keyboardShortcuts,
      shortcutsMac,
      shortcutsWindows,
    ]
  );

  useHotkeys('*', handleKeyPress);
};
